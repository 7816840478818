import { CdkVirtualScrollViewport, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { Component, OnInit, Output, EventEmitter, ViewChild, NgZone } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GamesApiservice } from "@api/services/games/games-api.service";
import { LotoUpService } from "@api/services/LotoUp.service";
import { CustomVirtualScroll } from "@classes/CustomVritualScoll";
import { Number } from "@models/LotoUp_search.model";
import { BetService } from "@modules/games/bet.service";
import { ToastrService } from 'ngx-toastr';
import { filter, map, pairwise, take, throttleTime } from 'rxjs/operators';

@Component({
    selector:'app-puntos-venta-list',
    templateUrl:'./puntos-venta-list.component.html',
    styleUrls: ['./puntos-venta-list.component.scss'],
    providers:[{provide:VIRTUAL_SCROLL_STRATEGY,useClass:CustomVirtualScroll}]
})
export class puntosVentaListComponent implements OnInit
{
    public urTicket: string;
    public load: boolean=false;
    public listaAdministrations: Array<Number> = []
    public spinnerBottomLoadVisibility = false
    private page = 1

    @ViewChild("scroll") scroller: CdkVirtualScrollViewport;
    @Output() loadEv: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(public betService: BetService,
                public lotoUpService: LotoUpService,
                public gameService: GamesApiservice,
                public ActiveRouter: ActivatedRoute,
                private router: Router,
                private ngZone: NgZone,
                private toastr: ToastrService
    ) { }

    ngOnInit(){
        this.loadDecimos();
    }

    loadDecimos()
    {
        this.ActiveRouter.queryParams.subscribe(response=>{

            this.loadPuntosVenta({ "fechaSorteo" : response.date, "terminacion": response.num});

            this.gameService.getLottery(response.date).subscribe(x=>{
                this.urTicket=this.getTicket(x.listadoSorteos);
            });
        });
    }

    loadPuntosVenta(params: any)
    {
        this.lotoUpService.searchDecimo(params.fechaSorteo,params.terminacion,this.page).subscribe(x=>{
            if(x.status == '200')
                this.listaAdministrations.push(...x.data)

            this.load=true;
            this.spinnerBottomLoadVisibility = false
            this.loadEv.emit(true);
        }, () => {
            this.toastr.error("Puntos de venta no encontrados.")
            this.router.navigate(['/'])
        });
    }

    /**
     * Metodo para saber cuando hay que scrollear
     */
    onScroll()
    {
      this.scroller.elementScrolled().pipe(
        map(()=> this.scroller.measureScrollOffset('bottom')),
        pairwise(),
        filter(([y1,y2])=> (y2 < y1 && y2 > 140)),
        throttleTime(1500),
      ).subscribe(()=>{
        this.ngZone.run(()=>{
          this.page ++;

          if(this.page > 1)
            this.spinnerBottomLoadVisibility = true;

          this.loadDecimos();
        })
      })
    }

    getTicket(listado: any)
    {
        let url = "/assets/images/icons/defaultTicket.svg";

        if(listado[0] && listado[0].miniaturas[1])
            url="http://www.loteriaelnegrito.com/uploads/"+listado[0].miniaturas[1].path+listado[0].miniaturas[1].nombre;
        
        return url;
    }
}