<div class="play-wrapper">
	<img *ngIf="!play.producto && !play.participacion" [ngClass]="play.tipo == 'penyas' ? 'play-game-product' : 'play-game-icon'" [src]="play.imagen" (click)="detailPlay(play)" />
	<div *ngIf="play.participacion">
		<div class="fondo imagen" *ngIf="play.participacion.producto.penya.imagen == null" (click)="detailPlay(play)">
			<img class="filtro" src="/assets/images/icons/penya.svg" alt="Imagen Peña">
		</div>
		<div class="imagen" *ngIf="play.participacion.producto.penya.imagen != null" (click)="detailPlay(play)">
			<img src="{{play.participacion.producto.penya.imagen}}" alt="Imagen Peña">
		</div>
	</div>

	<img *ngIf="play.producto && play.miniatura" class="play-game-mini"
		src="https://www.loteriaelnegrito.com/uploads/{{play.miniatura.path}}{{play.miniatura.nombre}}"
		(click)="detailPlay(play)" />
	<img *ngIf="play.producto && !play.miniatura" class="play-game-mini"
		src="/assets/images/icons/plays-list/icons-list/ticket.png" (click)="detailPlay(play)" />
	<div class="play-details" (click)="detailPlay(play)">
		<div class="play-info">
			<span class="play-game-name">{{play.nombre}}</span>
			<span class="play-game-price">{{play.importe}}€</span>
		</div>
		<div class="play-bet" (click)="detailPlay(play)">

			<span *ngIf="play.tipo == 'penyas'; then producto else notProducto"></span>

			<ng-template #producto>
				<span style="font-weight: 600;">{{play.participacion.producto.penya.nombre}}</span>
			</ng-template>
			<ng-template #notProducto>
				<h4 class="bet-numbers">

					<!-- Apuesta Numeracion -->
					<span *ngFor="let number of play.jugada.betsNumbers[0]">

						<span
							*ngIf="play.jugada.idGame == 6 ||play.jugada.idGame == 7 ||play.jugada.idGame == 8; then lottery else notLottery"></span>
						<ng-template #lottery>
							<span style="margin-right: 5px;">{{number}}</span>
						</ng-template>
						<ng-template #notLottery>
							<div *ngIf="number < 10; then addZero else withoutZero"></div>

							<ng-template #addZero>
								<span style="margin-right: 5px;">0{{number}}</span>
							</ng-template>

							<ng-template #withoutZero>
								<span style="margin-right: 5px;">{{number}}</span>
							</ng-template>
						</ng-template>
					</span>

					<!-- Apuesta Estrellas -->
					<span *ngIf="play.jugada.idGame == 3">
						<span *ngFor="let number of play.jugada.valueStars[0].estrellas">

							<div *ngIf="number < 10; then addZero else withoutZero"></div>

							<ng-template #addZero>
								<span>
									<img class="bet-stars" src="/assets/images/icons/plays-list/star.svg" />0{{number}}
								</span>
							</ng-template>

							<ng-template #withoutZero>
								<span>
									<img class="bet-stars" src="/assets/images/icons/plays-list/star.svg" />{{number}}
								</span>
							</ng-template>
						</span>
					</span>

					<!-- Apuesta Estrellas -->
					<span *ngIf="play.jugada.idGame == 2">
						<span *ngFor="let number of play.jugada.valueStars[0].estrellas">
							🌸{{number}}
						</span>
					</span>

					<!-- Apuesta Reintegro -->
					<span *ngIf="(play.jugada.idGame == 1 || play.jugada.idGame == 4 || play.jugada.idGame == 5) && !play.jugada.producto">

						<span *ngIf="play.jugada.refund; then refund else waitRefund"></span>

						<ng-template #refund>
							<span>R: {{ play.jugada.refund }}</span>
						</ng-template>
						<ng-template #waitRefund>
							<span>R: *</span>
						</ng-template>

					</span>

					<span *ngIf="play.jugada.producto">
						{{play.jugada.nombre_producto}}
					</span>

				</h4>
			</ng-template>


		</div>
	</div>
	<img class="image-status" src="/assets/images/icons/plays-list/sub_icon.svg" *ngIf="abono"
		[attr.title]="!aleatorio ? 'Suscripción' : 'Suscripción Aleatoria'">
	<img *ngIf="!play.producto" class="play-game-details" src="/assets/images/icons/play-detail/play-details.svg"
		(click)="openOptions()">
</div>
<div class="actions-play" *ngIf="selected">
	<div class="group-action" *ngIf="!abono" (click)="openAbono()" >
        <img src="/assets/images/icons/play-detail/abono-play.svg">
        <span class="action-name">Suscribirse</span>
    </div>
    <div class="group-action" *ngIf="abono" (click)="desabono()">
        <img src="/assets/images/icons/play-detail/abono-play-full.svg">
        <span class="action-name">Desuscribirse</span>
    </div>
</div>