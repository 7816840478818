import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CCoreComponent } from "@classes/core/core-component.class";
import { ApplicationStateService } from "@shared/services/application-state.service";

/**
 * @title Stepper overview
 */
@Component({
	selector: 'app-modal-tour-core',
	templateUrl: './modal-tour-core.component.html',
	styleUrls: ['./modal-tour-core.component.scss'],
})

export class ModalTourCoreComponent extends CCoreComponent {

	constructor(@Inject(MAT_DIALOG_DATA) public data: {slidesDesktop: Array<any>, slidesMobile: Array<any>},
		AplicationStateService: ApplicationStateService,
		public dialogRef: MatDialogRef<ModalTourCoreComponent>) { 
			super(AplicationStateService);
	}

	dismiss(): void {
		this.dialogRef.close();
	}
}