import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn:'root'
})
export class HeaderService
{
    private btnActionLeftMode: string = "Ajustes";
    private showBtnRight: boolean = true;
    private title: string = "";
    private buttonRightShow: boolean = false;
    private SeconButtonRightIcon: string = "";
    private SeconButtonRightRoute: string = "";
    private modeGrupoWhite: boolean = false;
    private modeGrupoCool: boolean = false;
    private rutaBack: string = "/";
    private filterButtonShow: boolean = false;
    private showHeader: Boolean = true;
	private promoBanner: boolean = true;

    //visibilidad del header
    private showHeade$: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(this.showHeader);
    public showHeaderOb: Observable<Boolean> = this.showHeade$.asObservable();

    //Boton Izquierdo
    private btnActionLeftMode$: BehaviorSubject<string> = new BehaviorSubject<string>(this.btnActionLeftMode);
    public getBtnActionLeftMode: Observable<string> = this.btnActionLeftMode$.asObservable();

    //Ruta Bottom Izquierdo (Back)
    private rutaBack$: BehaviorSubject<string> = new BehaviorSubject<string>(this.rutaBack)
    public rutaBackOb: Observable<string> = this.rutaBack$.asObservable();

    //Boton Scaner derecho ... (Por quitar)
    private showBtnRight$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.showBtnRight);
    public ShowBtnRightOb: Observable<boolean> = this.showBtnRight$.asObservable();

    //Titulo del Header
    private title$: BehaviorSubject<string> = new BehaviorSubject<string>(this.title);
    public getTitle: Observable<string> = this.title$.asObservable();

    //Boton Derecho Show (True | False)
    private showSeconButtonRight$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.buttonRightShow);
    public showSeconButtonRightOb: Observable<boolean> = this.showSeconButtonRight$.asObservable();

    //Boton Derecho Icon
    private SeconButtonRightIcon$: BehaviorSubject<string> = new BehaviorSubject<string>(this.SeconButtonRightIcon);
    public SeconButtonRightIconOb: Observable<string> = this.SeconButtonRightIcon$.asObservable();

    //Boton Derecho Ruta
    private SeconButtonRightRoute$: BehaviorSubject<string> = new BehaviorSubject<string>(this.SeconButtonRightRoute);
    public SeconButtonRightRouteOb: Observable<string> = this.SeconButtonRightRoute$.asObservable();

    //Modo Grupo (White)
    private modeGrupoWhite$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.modeGrupoWhite);
    public modeGrupoWhiteOb: Observable<boolean> = this.modeGrupoWhite$.asObservable();

    //Modo Grupo (Cool)
    private modeGrupoCool$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.modeGrupoCool);
    public modeGrupoCoolOb: Observable<boolean> = this.modeGrupoCool$.asObservable();

    //Show filter button
    private filterButtonShow$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.filterButtonShow);
    public filterButtonShowOb: Observable<boolean> = this.filterButtonShow$.asObservable();
    
	//Show Promotion Banner
    private promoBanner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.promoBanner);
    public promoBannerOb: Observable<boolean> = this.promoBanner$.asObservable();

    constructor(){}

    set_show_header(show: boolean)
    {
        this.showHeader = show
        this.showHeade$.next(show)
    }

    set_btn_action_left_Mode(mode: string)
    {
        this.btnActionLeftMode=mode;
        this.btnActionLeftMode$.next(mode);
    }

    set_btn_right_show(show: boolean)
    {
        this.showBtnRight=show;
        this.showBtnRight$.next(show);
    }

    set_title(texto: string)
    {
        this.title=texto;
        this.title$.next(texto);
    }

    set_secondaryButtonShow(show: boolean)
    {
        this.buttonRightShow=show;
        this.showSeconButtonRight$.next(show);
    }

    set_RightIcon(url: string)
    {
        this.SeconButtonRightIcon=url;
        this.SeconButtonRightIcon$.next(url);
    }

    set_SeconButtonRightRoute(url: string)
    {
        this.SeconButtonRightRoute=url;
        this.SeconButtonRightRoute$.next(url);
    }

    set_modeGruopWhite(active: boolean)
    {
        this.modeGrupoWhite=active;
        this.modeGrupoWhite$.next(active);
    }

    set_modeGrupoCool(active: boolean)
    {
        this.modeGrupoCool = active;
        this.modeGrupoCool$.next(active);
    }

    set_rutaBack(ruta: string)
    {
        this.rutaBack = ruta;
        this.rutaBack$.next(ruta)
    }

    set_filterButtonShow(show: boolean)
    {
        this.filterButtonShow = show;
        this.filterButtonShow$.next(show);
    }

	set_promoBanner(show: boolean) {
		this.promoBanner = show;
		this.promoBanner$.next(show);
	}

}