import { Injectable } from "@angular/core";


/*
export interface ITemplateFilter {
    paramFilter: String;
    paramList: any[];
    valueFilter: Date | number | String;
    resultList: any[];


    filter: ()=> any[];
}

@Injectable({
    providedIn: 'root',
  })
export class FilterConceptService implements ITemplateFilter{
    
    paramFilter: String;
    paramList: any[];
    valueFilter: String;
    resultList: any[];

    constructor(
        param: String,
        pList: any[], 
        value: String)
    {

        this.paramFilter = param;
        this.paramList = pList;
        this.valueFilter = value;
        this.resultList = [];
    }
    filter(): any[]{
      
      for(var item of this.paramList){

          for(var key in item){

             if(key === this.paramFilter){
                 if(item[key].trim().toLowerCase() === this.valueFilter){
                       this.resultList.push(item);
                 }
             }
          }
          

      }
      return this.resultList;

    }
}*/




@Injectable({
    providedIn: 'root',
  })
export class TemplateFilterService{


    /**
     * 
     * Filter the moves by concept in balance component
     * @param list 
     * @param paramFilter 
     * @param valueFilter 
     * 
     * @returns any[]
     */
    public filterConcept(list: any[], paramFilter: String, valueFilter: String): any[] {

    
        //var resultList: any[];
        var resultList: any[];
        resultList = [];
        for(var item of list){

            for(var key in item){

               if(key === paramFilter){
                   if(item[key].trim().toLowerCase() === valueFilter){
                         resultList.push(item);
                   }
               }
            }
            

        }
        return resultList;

    }

      /**
     * 
     * Filter the moves by date in balance component
     * @param list 
     * @param paramFilter 
     * @param valueFilter 
     * 
     * @returns any[]
     */
    public filterDate(list: any[], paramFilter: String, valueFilter: Date): any[] {

    
        //var resultList: any[];
        var resultList: any[];
        resultList = [];
        for(var item of list){

            for(var key in item){

               if(key === paramFilter){
                 
                   if(item[key].getDate() === valueFilter.getDate()){
                       if(item[key].getMonth() === valueFilter.getMonth()){
                           if(item[key].getFullYear() === valueFilter.getFullYear()){

                            resultList.push(item);

                           }
                       }
                         
                   }
               }
            }
            

        }
        return resultList;

    }


      /**
     * 
     * Filter the plays by his name in plays-list component
     * @param list 
     * @param paramFilter 
     * @param valueFilter 
     * 
     * @returns any[]
     */
    public filterGames(list: any[], paramFilter: String, valueFilter: String): any[] {

    
        //var resultList: any[];
        var resultList: any[];
        resultList = [];
        
        for(var item of list){

            for(var key in item){

                if(key == paramFilter){

                    if(item[key] == valueFilter){

                        resultList.push(item);

                    }

                }

            }

        }
        
        return resultList;

    }

    

}
