import { Component, Inject, Input } from "@angular/core";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-modalyesno',
	templateUrl: './modalYesNo.component.html',
	styleUrls: ['./modalYesNo.component.scss']
})
export class modalYesNoComponent {
	checkboxState: boolean = false;

	constructor(@Inject(MAT_DIALOG_DATA) public data: {
		title: string,
		texto: string,
		buttonConfirm: string,
		btnConfirmColor: string,
		buttonCancel: string,
		showIcon: boolean,
		showOptionCheck: boolean,
		slug?: string
	}) { }

	public dangerBtn() {
		if (this.data.btnConfirmColor == 'red')
			return true;
		return false;
	}

	public groupMode() {
		if (this.data.btnConfirmColor == 'cool')
			return true;
		return false;
	}

	public onClose(result: boolean) {
		if (result && this.data.showOptionCheck && this.data.slug && this.checkboxState) {
			localStorage.setItem(this.data.slug, 'true');
		}
		return result;
	}

	public onCheckboxChange(event: any) {
		this.checkboxState = event.target.checked;
	}
}