import { Component, OnInit } from "@angular/core";

@Component({
	selector: 'app-banner-download-app',
	templateUrl: './banner-download-app.component.html',
	styleUrls: ['./banner-download-app.component.scss']
})
export class BannerDownloadAppComponent implements OnInit {

	public showBannerApp = false

	constructor() { }

	ngOnInit(): void {
		this.detectDevice()
	}

	private detectDevice() {

		var userAgent = navigator.userAgent

		if(localStorage.getItem("download_app") != "true")
				this.showBannerApp = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent)
			else
				this.showBannerApp = false
	}

	downloadApp() {
		var userAgent = navigator.userAgent

		if(/Android|webOS|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent) && !(/iPhone|iPad|iPod/i.test(userAgent)))
		{
			window.open("https://play.google.com/store/apps/details?id=com.miloto&pli=1", "_blank")
			localStorage.setItem("download_app", "true")
			this.showBannerApp = false
		}
		else {
			if(/iPhone|iPad|iPod|Mobile|mobile/i.test(userAgent)) {
				window.open("https://apps.apple.com/es/app/miloto-loter%C3%ADas-del-estado/id1613710351", "_blank")
				localStorage.setItem("download_app", "true")
				this.showBannerApp = false
			}
		}
	}
}