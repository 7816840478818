<div class="games-footer-wrapper">

	<div class="footer-row">

		<div class="currency">
			<span class="number">{{ saldoCliente | currency:'EUR'}}</span>
			<span class="label">Tu Saldo</span>
		</div>

		<div class="separator"></div>

		<div class="currency">
			<span class="number">{{ cost + (guinda ? guindaCost : 0) | currency:'EUR' }}</span>
			<span class="label">Apuesta</span>
		</div>

		<div class="separator" *ngIf="donacion"></div>

		<div class="donation-wrapper" *ngIf="donacion">
			<div class="cost">
				<span>{{ donacion | currency:'EUR' }}</span>
				<img class="icon"
					matTooltip="Cada décimo tendrá un recargo de {{ donacion | number: '.2-2'}}€ como donativo . {{empresa.info_donacion}}"
					matTooltipTouchGestures="on"
					src="/assets/images/icons/balance/icono info.png" />

			</div>
			<span class="cost-text">Donación</span>
		</div>
		<div class="toRight">
			<button (click)="playBet()" [disabled]='disabled' [ngClass]="disabled ? 'disabled-button' : activeClass"
			[matTooltip]="empresa && empresa.compra_minima > 0 && disabled ? 'Compra mínima ' + empresa.compra_minima + ' numeros': ''">
			<ng-container *ngIf="!loading">
				{{titlebutton}}
			</ng-container>

			<mat-spinner *ngIf="loading" [diameter]="30"></mat-spinner>
		</button>
		</div>


	</div>

</div>