import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule, MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { GamesPrimaryHeaderComponent } from '@modules/games/components/header/games-primary-header.component';
import { QuinielaPrimaryHeaderComponent } from '@modules/games/components/header-quiniela/games-primary-header-quiniela.component';
import { GamesFooterComponent } from '@modules/games/components/footer/games-footer.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareButtonModule } from 'ngx-sharebuttons/button';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { NgScrollbarModule } from 'ngx-scrollbar';

// SHARED COMPONENTS
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';


import { BetService } from '@modules/games/bet.service';
import { AdviceBetComponent } from '@modules/games/components/advice-bet/advice-bet.component';
import { DayComponent } from '@modules/games/components/header/day/day.component';
import { AutomaticPlayBetComponent } from '@modules/games/components/play-bet/automatic-play-bet/automatic-play-bet.component';
import { ManualPlayBetComponent } from '@modules/games/components/play-bet/manual-play-bet/manual-play-bet.component';
import { ToastComponent } from '@shared/components/toast/toast.component';
import { AutomaticPlayLotteryComponent } from '@modules/games/components/play-lottery/automatic-play-lottery/automatic-play-lottery.component';
import { PlayedLotteryComponent } from '@modules/games/components/play-lottery/played-lottery/played-lottery.component';
import { PlayedComponent } from '@modules/games/components/play-bet/played/played.component';
import { ManualPlayLotteryComponent } from '@modules/games/components/play-lottery/manual-play-lottery/manual-play-lottery.component';
import { ManualPlayLotteryMobileComponent } from '@modules/games/components/play-lottery/manual-play-lottery/mobile/manual-play-lottery-mobile.component';
import { LotteryPrimaryHeaderComponent } from '@modules/games/components/header-lottery/games-primary-header-lottery.component';
import { DayLotteryComponent } from '@modules/games/components/header-lottery/day/day-lottery.component';
import { modalYesNoComponent } from '@shared/components/modals/modalYesNo/modalYesNo.component';
import { modalInfoComponent } from '@shared/components/modals/modalInfo/modalInfo.component';
import { modalShareComponent } from '@shared/components/modals/modalShare/modalShare.component';
import { bottomSheetParticipantesComponent } from '@shared/components/bottomSheet/bottomSeetParticipantes/bottomSheetParticipantes.component';
import { bottomSheetQuinielaResultComponent } from '@shared/components/bottomSheet/bottomSheetQuinielaResult/bottomSheetQuinielaResult.component';
import { bottomSheetAgendaComponent } from '@shared/components/bottomSheet/bottomSheetAgenda/bottomSheetAgenda.component';
import { bottomSheetJugadaRegaladaComponent } from '@shared/components/bottomSheet/bottomSheetJugadaRegalada/bottomSheetJugadaRegalada.component';
import { bottomSheetRegalarComponent } from '@shared/components/bottomSheet/bottomSheetRegalar/bottomSheetRegalar.component';
import { bottomSheetAceptarJugadaComponent } from '@shared/components/bottomSheet/bottomSheetAceptarJugada/bottomSheetAceptarJugada.component';
import { bottomSheetAceptarCompartidaComponent } from '@shared/components/bottomSheet/bottomSheetAceptarCompartida/bottomSheetAceptarCompartida.component';
import { bottomSheetAbonoComponent } from '@shared/components/bottomSheet/bottomSheetAbono/bottomSheetAbono.component';

import { bottomSheetPremiosComponent } from '@shared/components/bottomSheet/bottomSheetPremios/bottomSheetPremios.component';
import { bottomSheetPremiosParticipacionComponent } from '@shared/components/bottomSheet/bottomSheetPremiosParticipacion/bottomSheetPremiosParticipacion.component';
import { bottomSheetTrazaJugadaComponent } from '@shared/components/bottomSheet/bottomSheetTrazaJugada/bottomSheetTrazaJugada.component';
import { puntosVentaLotteryComponent } from '@modules/games/components/play-lottery/puntos-venta-lottery/puntos-venta-lottery.component';
import { puntosVentaListComponent } from '@modules/games/components/play-lottery/puntos-venta-lottery/puntos-venta-list/puntos-venta-list.component';
import { puntosVentaDesktopComponent } from '@modules/games/components/play-lottery/puntos-venta-lottery/desktop/puntos-venta-desktop.component';
import { puntosVentaMobileComponent } from '@modules/games/components/play-lottery/puntos-venta-lottery/mobile/puntos-venta-mobile.component';
import { formaEnvioComponent } from '@modules/games/components/play-lottery/form-envio/forma-envio.component';
import { direccionCompraCoreComponent } from '@modules/games/components/direccion-compra/direccion-compra-core.component';
import { direccionCompraDesktopComponent } from '@modules/games/components/direccion-compra/desktop/direccion-compra-desktop.component';
import { direccionCompraMobileComponent } from '@modules/games/components/direccion-compra/mobile/direccion-compra-mobile.component';
import { direccionCompraViewComponent } from '@modules/games/components/direccion-compra/direccion-compra-view/direccion-compra-view.component';
import { modalAlertaComponent } from '@shared/components/modals/modalAlerta/modalAlerta.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { BottomSheetFiltros } from '@shared/components/bottomSheet/bottomSheetFiltros/bottomSheetFiltros.component';
import { BottomSheetMovimientos } from '@shared/components/bottomSheet/bottomSheetMovimiento/bottomSheetMovimiento.component';
import { HeaderGrupoModeComponent } from '@shared/components/header/header-grupo-mode.component';
import { BottomSheetRepetirJugadaComponent } from '@shared/components/bottomSheet/bottomSheetRepetirJugada/bottomSheetRepetirJugada.component';
import { UserImgComponent } from '@shared/components/user_img/user-img.component';
import { PlaysListComponent } from '@modules/client/my-plays/plays-list/plays-list.component';
import { PlayComponent } from '@modules/client/my-plays/plays-list/play-component/play.component';
import { ParticipationProductoComponent } from '@modules/client/my-plays/participations-products-list/participation-product-item/participation-product.component';
import { SubscriptionListComponent } from '@modules/client/my-plays/subscriptions-list/subscription-list.component';
import { SubscriptionComponent } from '@modules/client/my-plays/subscriptions-list/subscription-component/subscription.component';
import { BottomSheetChangeShipmentForm } from '@shared/components/bottomSheet/bottomSheetChangeShipmentForm/bottomSheetChangeShipmentForm';
import { listNumerosLoteriaBottomSheetComponent } from '@shared/components/bottomSheet/lista-numeros-loteria-bottom-sheet/lista-numeros-loteria-bottom-sheet.component';
import { bottomSheetNombreJugada } from '@shared/components/bottomSheet/bottomSheetNombreJugada/bottomSheetNombreJugada.component';
import { modalAutoexclusionComponent } from '@shared/components/modals/modalAutoexclusion/modalAutoexclusion.component';
import { bottomSheetDireccion } from '@shared/components/bottomSheet/bottomSheetDireccion/bottomSheetDireccion.component';
import { BannerDownloadAppComponent } from '@shared/components/bannerDownloadApp/banner-download-app.component';
import { modalPinComponent } from '@shared/components/modals/modalPin/modalPin.component';
import { ModalTourCoreComponent } from '@shared/components/modals/modalTour/modal-tour-core.component';
import { ModalTourMobileComponent } from '@shared/components/modals/modalTour/mobile/modal-tour-mobile.component';
import { ModalTourDesktopComponent } from '@shared/components/modals/modalTour/desktop/modal-tour-desktop.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { modalLegalComponent } from '@shared/components/modals/modalLegal/modalLegal.component';
import { SingleMovementComponent } from '@shared/components/singleComponents/singleMovementComonent/single-movement.component';
import { ParticipationsProductsListComponent } from '@modules/client/my-plays/participations-products-list/participations-products-list.component';
import { modalAccesoEmpresaComponent } from '@shared/components/modals/modalAccesoEmpresa/modalAccesoEmpresa.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
	NgScrollbarModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule,
	InfiniteScrollModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareButtonModule,
    ShareIconsModule,
    NgApexchartsModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
	InfiniteScrollModule,
    MatAutocompleteModule,
    MatBadgeModule,
	NgScrollbarModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    BottomSheetMovimientos,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule,
    FontAwesomeModule,
    SpinnerComponent,
    ToastComponent,
    DayComponent,
    DayLotteryComponent,
    AdviceBetComponent,
    ManualPlayBetComponent,
    AutomaticPlayBetComponent,
	PlayedLotteryComponent,
	PlayedComponent,
    ManualPlayLotteryComponent,
    AutomaticPlayLotteryComponent,
    ManualPlayLotteryMobileComponent,
    GamesPrimaryHeaderComponent,
    QuinielaPrimaryHeaderComponent,
    LotteryPrimaryHeaderComponent,
    GamesFooterComponent,
    modalYesNoComponent,
    modalInfoComponent,
    modalAlertaComponent,
    modalShareComponent,
    ShareButtonsModule,
    ShareButtonModule,
    ShareIconsModule,
    bottomSheetParticipantesComponent,
    bottomSheetQuinielaResultComponent,
	bottomSheetAgendaComponent,
    bottomSheetJugadaRegaladaComponent,
    bottomSheetRegalarComponent,
    bottomSheetPremiosComponent,
    bottomSheetPremiosParticipacionComponent,
    bottomSheetAceptarJugadaComponent,
    bottomSheetAceptarCompartidaComponent,
    bottomSheetTrazaJugadaComponent,
    bottomSheetAbonoComponent,
    BottomSheetFiltros,
    puntosVentaLotteryComponent,
    puntosVentaListComponent,
    puntosVentaDesktopComponent,
    puntosVentaMobileComponent,
    formaEnvioComponent,
    direccionCompraCoreComponent,
    direccionCompraDesktopComponent,
    direccionCompraMobileComponent,
    direccionCompraViewComponent,
    NgApexchartsModule,
    HeaderGrupoModeComponent,
    UserImgComponent,
    PlaysListComponent,
    PlayComponent,
	SubscriptionListComponent,
	SubscriptionComponent,
	ParticipationProductoComponent,
    BottomSheetChangeShipmentForm,
    listNumerosLoteriaBottomSheetComponent,
    bottomSheetNombreJugada,
	bottomSheetDireccion,
	BannerDownloadAppComponent,
	modalPinComponent,
	ModalTourCoreComponent,
	ModalTourMobileComponent,
	ModalTourDesktopComponent,
	modalLegalComponent,
	SingleMovementComponent,
	ParticipationsProductsListComponent,
	modalAccesoEmpresaComponent
  ],
  declarations: [
    AdviceBetComponent,
    SpinnerComponent,
    ToastComponent,
    DayComponent,
    DayLotteryComponent,
    ManualPlayBetComponent,
    AutomaticPlayBetComponent,
    ManualPlayLotteryComponent,
    AutomaticPlayLotteryComponent,
	PlayedLotteryComponent,
	PlayedComponent,
    ManualPlayLotteryMobileComponent,
    GamesPrimaryHeaderComponent,
    QuinielaPrimaryHeaderComponent,
    LotteryPrimaryHeaderComponent,
    GamesFooterComponent,
    modalYesNoComponent,
    modalInfoComponent,
    modalAlertaComponent,
    modalShareComponent,
    bottomSheetParticipantesComponent,
    bottomSheetQuinielaResultComponent,
	bottomSheetAgendaComponent,
    bottomSheetJugadaRegaladaComponent,
    bottomSheetRegalarComponent,
    bottomSheetPremiosComponent,
    bottomSheetPremiosParticipacionComponent,
    BottomSheetMovimientos,
    bottomSheetAceptarJugadaComponent,
    bottomSheetAceptarCompartidaComponent,
    bottomSheetTrazaJugadaComponent,
    bottomSheetAbonoComponent,
    BottomSheetRepetirJugadaComponent,
    BottomSheetFiltros,
    puntosVentaLotteryComponent,
    puntosVentaListComponent,
    puntosVentaDesktopComponent,
    puntosVentaMobileComponent,
    formaEnvioComponent,
    direccionCompraCoreComponent,
    direccionCompraDesktopComponent,
    direccionCompraMobileComponent,
    direccionCompraViewComponent,
    HeaderGrupoModeComponent,
    UserImgComponent,
    PlaysListComponent,
    PlayComponent,
	SubscriptionListComponent,
	SubscriptionComponent,
	ParticipationProductoComponent,
    BottomSheetChangeShipmentForm,
    listNumerosLoteriaBottomSheetComponent,
    bottomSheetNombreJugada,
	modalAutoexclusionComponent,
	bottomSheetDireccion,
	BannerDownloadAppComponent,
	modalPinComponent,
	ModalTourCoreComponent,
	ModalTourMobileComponent,
	ModalTourDesktopComponent,
	modalLegalComponent,
	SingleMovementComponent,
	ParticipationsProductsListComponent,
	modalAccesoEmpresaComponent
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: {} }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GlobalModule { }
