import { Component, Inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { empresaService } from "@api/services/empresa.service";
import { loteriaEmpresaModel } from "@models/api-models/loteria_empresa.models";

@Component({
	selector: 'app-modalAccesoEmpresa',
	templateUrl: './modalAccesoEmpresa.component.html',
	styleUrls: ['./modalAccesoEmpresa.component.scss']
})
export class modalAccesoEmpresaComponent {
	public dynamicForm: FormGroup;
	public labels: string[];

	constructor(public dialogRef: MatDialogRef<modalAccesoEmpresaComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { empresa: loteriaEmpresaModel },
		private fb: FormBuilder,
		private empresaService: empresaService
	) {
		this.dynamicForm = this.fb.group({});
		this.initializeForm();
	}

	initializeForm() {
		this.labels = this.data.empresa.ac_data_values.split(',');

		this.labels.forEach((label, index) => {
		this.dynamicForm.addControl(`param${index}`, new FormControl(''));
		});
	}

	onSubmit() {
		let formValues = this.dynamicForm.value;
		let result = Object.values(formValues).join(',');
		this.empresaService.getAccessControl(result, this.data.empresa.id).subscribe((res: any) => {
			this.dialogRef.close(res.data);
		});
	}

	onCancel(): void {
		this.dialogRef.close();
	}
}