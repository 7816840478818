import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Estado, PlaysFilter } from '@models/playsFilter';
import { ApiRouterService } from '@shared/services/api-router.service';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { MPlay } from '@models/api-models/plays-api.models';
import { BehaviorSubject } from 'rxjs';

/**
 * Plays API service. Provides the app with plays data from the API.
 */
@Injectable({
		providedIn: 'root'
})
export class ScannedPlayService extends ApiRouterService {

	private selectJugada: MPlay;
	private selectJugada$: BehaviorSubject<MPlay>;
	public selectJugadaOb: Observable<MPlay>;

	constructor(

		private http: HttpClient,
		private authService: AuthenticationService

	) {

		super();

		this.selectJugada$ = new BehaviorSubject<MPlay>(this.selectJugada);
		this.selectJugadaOb = this.selectJugada$.asObservable();

	}

	set_SelectJugada(jugada: MPlay) {
		this.selectJugada = jugada;
		this.selectJugada$.next(jugada);
	}

	public setNombreJugada(jugada: MPlay) {
		const token = this.authService.loadToken();

		let header = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		return this.http.post(`${this.URL}jugadas_escaneadas/${jugada.id}/cambionombre`, { nombre: jugada.nombre }, { headers: header });
	}

	public borrarJugada(jugada: MPlay) {
		const token = this.authService.loadToken();

		let header = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		return this.http.delete(`${this.URL}jugadas_escaneadas/${jugada.id}`, { headers: header });
	}

	/**
	 * Gets all content of the plays from the API.
	 * @returns Callback from the API.
	 */
	public getPlays(page=1, filtros: PlaysFilter): Observable<any> {

		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);

		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		const httpOptions = { headers: this.httpHeaders };
		return this.http.get(`${this.URL}jugadas_escaneadas/${tokenJWT.idClient}?page=${page}&estado=${filtros.estado}&juego=${filtros.juego_id}`, httpOptions);

	}

	/**
	 * Gets all content of the play from the API.
	 * @returns Callback from the API.
	 */
	public getPlay(id: number): Observable<any> {

		const token = this.authService.loadToken();

		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		const httpOptions = { headers: this.httpHeaders };

		return this.http.get(`${this.URL}jugadas_escaneadas/detalle/${id}`, httpOptions);

	}

	/*public createPlay(play: MPlay) {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);

		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		let params = JSON.stringify(play);

		const httpOptions = { headers: this.httpHeaders };
		return this.http.post(`${this.URL}jugadas_escaneadas/${tokenJWT.idClient}/generar`, params, httpOptions);
	}*/

  public extractInfoApi(playsApi: Array<any>): Array<MPlay>{
    let plays: Array<MPlay>;
    plays = [];

    playsApi.forEach(play => {
        plays.push(this.extractInfoPlay(play));

    });

    return plays;
  }

/**
 * Extract the info of a play from api
 * @param play
 * @returns MPlay
 */
public extractInfoPlay(play: any): MPlay{
    const dateOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    let date =  (new Date(play['fecha_sorteo']!))
    .toLocaleDateString('es-ES', {year: 'numeric', month: 'long', day: 'numeric'}).charAt(0).toUpperCase() +
    (new Date(play['fecha_sorteo']!)).toLocaleDateString('es-ES', {year: 'numeric', month: 'long', day: 'numeric'}).slice(1);

    let betsNumbers = [];
    let auxStars = [];
    for(let index in play['apuestas']){
        let bet = play['apuestas'][index];
        betsNumbers.push(bet['numeros']);
        auxStars.push( bet['valores_auxiliares'] );
    }

    let valueStars;
    if(auxStars != null){
        valueStars = auxStars;
    }

    let refund = play['valores_jugada'];
    let loteriaSerieFraccion = null;

	let relacionadas = play["relacionadas"]

    let refundPlay;
    if(refund != null){
        refundPlay = refund['reintegro'] || refund['clave'];
        if(refund['serie'] && refund['fraccion'])
            loteriaSerieFraccion = {serie: refund['serie'], fraccion: refund['fraccion'] }
    }

    let state = play['estado'];
    if(play['premio'] > 0){
        state = 3;
    }

    let playAux;

    playAux = new MPlay(
        play['id'],
        date,
        play['juego']['nombre'],
        play['juego']['imagen'],
        state,
        betsNumbers,
        valueStars,
        refundPlay,
        null,
        true,
        play['precio'],
        null,
        play['juego_id'],
        null,
        relacionadas,
        null,
        null,
        null,
        play['fecha_sorteo']+" "+play['sorteo']['hora_sorteo'],
        null
    );

    playAux.valuesLottery = loteriaSerieFraccion;
    playAux.nombre = play.nombre;
    return playAux;

}
}
