<div class="modal">

    <div class="header">
		<div class="btn-accion" class="arrow" (click)="close()">
			<img src="/assets/images/icons/header/arrow-left.svg" alt="img"/>
		</div>

		<span class="title">{{title}}</span>

    </div>


	<div class="content">
		<app-login-desktop *ngIf="type == 'login'" (closeEvent)="closeModal($event)" (changeActionEvent)="changeAction($event)"></app-login-desktop>
		<app-register-desktop *ngIf="type == 'register'" (closeEvent)="closeModal($event)" (changeActionEvent)="changeAction($event)"></app-register-desktop>
		<app-recover-desktop *ngIf="type == 'recover'" (closeEvent)="closeModal($event)" (changeActionEvent)="changeAction($event)"></app-recover-desktop>
	</div>



</div>