import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UserApiService } from "@api/services/user.service";

@Component({
    selector:'app-modal-legal',
    templateUrl:'./modalLegal.component.html',
    styleUrls:['./modalLegal.component.scss']
})
export class modalLegalComponent
{
    constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string, mode: string, parameters: any},
		public userService: UserApiService,
		private dialogRef: MatDialogRef<modalLegalComponent>) {}
}