import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';

export interface IValidatorError {
	[s: string]: boolean;
}

/**
 * ValidatorsService provides a validator for forms.
 */
@Injectable({
	providedIn: 'root'
})
export class ValidatorsService {

	constructor() { }

	/**
	 * Check the user's age.
	 * @param control Form control from the register form.
	 *
	 * @returns Error validation check of legal age.
	 */
	public checkLegalAge(control: FormControl): IValidatorError | null {

		const minimumDate = new Date();
		minimumDate.setMonth(minimumDate.getMonth() - 216);
		const controlDate = moment(minimumDate).format('YYYY-MM-DD');
		const dateComparator = moment(control.value).format('YYYY-MM-DD');

		return dateComparator <= controlDate ? null : { error: true };

	}

	/**
	 * Check the user's DNI and NIE.
	 * @returns Error validation check of NIE and DNI
	 */
	public checkUserIdentifierDocument(control: FormControl): IValidatorError | null {
		var document = control.value

		const dniRegex = /[0-9]{8}[a-zA-Z]{1}/
		const nieRegex = /^[XYZ]\d{7,8}[A-Z]$/i;

		let isValid = dniRegex.test(document) || nieRegex.test(document)

		if (isValid) {
			let modifiedDocument = document;

			if (nieRegex.test(modifiedDocument)) {
				modifiedDocument = modifiedDocument.replace(/X/gi, '0');
				modifiedDocument = modifiedDocument.replace(/Y/gi, '1');
				modifiedDocument = modifiedDocument.replace(/Z/gi, '2');
			}

			const letters = ["T", "R", "W", "A", "G", "M", "Y", "F", "P", "D", "X", "B", "N", "J", "Z", "S", "Q", "V", "H", "L", "C", "K", "E"];

			const numbers = modifiedDocument.substring(0, document.length - 1);
			const letter = modifiedDocument.charAt(document.length - 1);

			const index = parseInt(numbers) % 23;

			isValid = index >= 0 && index < letters.length && letters[index] === letter;

			return isValid ? null : { error: true };
		}

		return { error: true }
	}

	/**
	 * Check the passwords are equals.
	 * @param controlName Form control from the register form.
	 * @param matchingControlName Form control from the register form.
	 *
	 * @returns Error validation check of identity document.
	 */
	public checkPasswords(controlName: string, matchingControlName: string): any {

		return (formGroup: FormGroup) => {

			const control = formGroup.controls[controlName];
			const matchingControl = formGroup.controls[matchingControlName];
			if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {

				return;

			}
			if (control.value !== matchingControl.value) {

				matchingControl.setErrors({ confirmedValidator: true });

			} else {

				matchingControl.setErrors(null);

			}

		};

	}

}
