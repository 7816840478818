export class AzureAD {

	constructor(
		public loggedazure: boolean = false,
		public empresa_id: number = null,
	) { }

	public isValidUser(id: number): boolean {
		return this.loggedazure && (this.empresa_id == id);
	}
}
