import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@api/services/authentication.service';
import { Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

interface RecoverPasswordMessage {
	type: 'error' | 'warning' | 'success';
	message: string;
}

@Component({
	selector: 'app-recover-desktop',
	templateUrl: './recover-desktop.component.html',
	styleUrls: [
		'./recover-desktop.component.scss'
	]
})
export class RecoverDesktopComponent {

	recoverPasswordMessage: RecoverPasswordMessage | null;

	recoverPasswordForm: FormGroup;
	loadingRecover: boolean = false;

	@Output() closeEvent = new EventEmitter<boolean>();
	@Output() changeActionEvent = new EventEmitter<string>();

	constructor(private authService: AuthenticationService, private toastrService: ToastrService) {

		this.recoverPasswordForm = new FormGroup({ email: new FormControl('', Validators.email) });
		this.recoverPasswordMessage = null;

	}

	closeModal() {
		this.closeEvent.emit(true);
	}

	changeAction(action: string) {
		this.changeActionEvent.emit(action);
	}

	/**
	 * Method that receives the submitted form from the view.
	 */
	public recoverPassword(): void {

		this.loadingRecover = true;

		if (this.recoverPasswordForm.valid) {

			this.authService.forguetPasswordComponent(this.recoverPasswordForm.controls.email.value).subscribe(x => {
				this.toastrService.success("Se te ha enviado un correo para recuperar tu contraseña.");
				this.recoverPasswordMessage = { type: 'success', message: 'Se te ha enviado un correo para recuperar tu contraseña.' };
				this.loadingRecover = false;
				this.closeModal();
			}, err => {
				this.toastrService.error('Hubo algun error al enviar el correo, es posible que el correo introducido no este registrado.');
				this.recoverPasswordMessage = { type: 'error', message: 'Hubo algun error al enviar el correo, es posible que el correo introducido no este registrado.' };
				this.loadingRecover = false;
			});

		} else {
			this.toastrService.error('El valor introducido no es válido.');
			this.recoverPasswordMessage = { type: 'error', message: 'El valor introducido no es válido.' };
			this.loadingRecover = false;

		}
	}
}
