import { Component, ElementRef, Renderer2, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "@api/services/authentication.service";
import { EAuthenticationAvailableOptions } from "@enums/common/authentication-options.enum";
import { LoginUserModel } from "@models/login-user.model";
import { ToastrService } from "ngx-toastr";
import { ValidatorsService } from "@api/services/validators.service";

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss']
})
export class changePasswordComponent {
	public changePasswordMessage: any;
	private hashCode: string;
	private showPassword: boolean = false;
	public first: Boolean = true;
	public hide: Boolean = true;
	public hideRepeat: Boolean = true;

	@ViewChild('newPassword') newPassword: ElementRef;
	@ViewChild('repeatPassword') repeatPassword: ElementRef;

	public changePasswordForm: FormGroup;

	constructor(private activeRouter: ActivatedRoute,
		private renderer: Renderer2,
		private authService: AuthenticationService,
		private toastrService: ToastrService,
		private router: Router,
		private fb: FormBuilder,
		private validatorsService: ValidatorsService
	) {
		this.changePasswordForm = this.fb.group({
			nuevoPassword: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_\\-#=+%$?&@!\\.*:\/]).{8,}$')])],
			repeatPassword: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_\\-#=+%$?&@!\\.*:\/]).{8,}$')])]
		}, {
			validator: this.validatorsService.checkPasswords('password', 'repeatPassword')
		});

		this.hashCode = this.activeRouter.snapshot.params.clave;
	}

	nofirst() {
		this.first = false;
	}

	hidePass() {
		this.hide = !this.hide;
	}

	hideRePass() {
		this.hideRepeat = !this.hideRepeat;
	}

	get passwordInvalid(): boolean | undefined {
		return this.changePasswordForm.get('nuevoPassword')?.invalid && this.changePasswordForm.get('nuevoPassword')?.touched;
	}

	changePasswordVisible(index: number) {

		let element: ElementRef;

		if (!index)
			element = this.newPassword;
		else
			element = this.repeatPassword;
		if (!this.showPassword) {
			this.showPassword = true;
			this.renderer.setProperty(element.nativeElement, 'type', 'text');
		}
		else {
			this.showPassword = false;
			this.renderer.setProperty(element.nativeElement, 'type', 'password');
		}
	}

	changePassword() {
		if (this.changePasswordForm.valid) {
			let data = {
				password: this.changePasswordForm.controls.nuevoPassword.value,
				repeatPassword: this.changePasswordForm.controls.repeatPassword.value
			};
			this.authService.changePassword(this.hashCode, data).subscribe((x: any) => {
				if (x.status) {
					let user: LoginUserModel = new LoginUserModel(x.data.email, data.password);
					this.authService.login(user).subscribe(authResponse => {
						this.authService.saveToken(authResponse.token, EAuthenticationAvailableOptions.JWT_LOGIN);

						if (localStorage.getItem("last-url")) {
							var url = localStorage.getItem("last-url")

							if (localStorage.getItem("empresa"))
								url = '/cliente/ingresar'
							else
								localStorage.removeItem("last-url")

							this.router.navigate([url])
						}
						else
							this.router.navigateByUrl('');
					});
				}
				else
					this.toastrService.error(x.u_message);
			})
		}
		else {
			this.changePasswordMessage = { type: 'error', message: 'Revisa si las contraseñas son iguales' }
		}
	}
}