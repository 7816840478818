import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiRouterService } from "@shared/services/api-router.service";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";

@Injectable({
    providedIn: 'root'
})
export class ShipmentFormService extends ApiRouterService
{
    private _pedido: BehaviorSubject<any> = new BehaviorSubject(null)
    public pedidoObserver = this._pedido.asObservable()

    constructor(private http: HttpClient, private authService: AuthenticationService) {
        super()
    }

    updateShipmentForm(pedidoId: number, envioId: number)
    {
        const token = this.authService.loadToken();
        let tokenJWT;
        if (token){
          tokenJWT = this.authService.decodeToken(token);
        }

        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            token
          });

        const httpOptions = {
            headers: this.httpHeaders
        };

        return this.http.get(`${this.URL}pedidos/${pedidoId}/cambiarFormaEnvio?envio_id=${envioId}`,httpOptions)
    }

    clearPedido(){
        this._pedido.next(null)
    }

    setPedido(pedido: any){
        this._pedido.next(pedido)
    }
}