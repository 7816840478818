import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ApiRouterService } from "@shared/services/api-router.service";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
providedIn: 'root'
})

export class DialogService extends ApiRouterService {
    public dataSourceLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public observableLoading: Observable<boolean> = this.dataSourceLoading.asObservable();

    constructor(private dialog: MatDialog) {

        super();
    
      }

    setStatusDialog(status: boolean){
        this.dataSourceLoading.next(status);
    }
}