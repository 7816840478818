import { Component } from "@angular/core";

@Component({
    selector:'app-puntos-venta-mobile',
    templateUrl:'./puntos-venta-mobile.component.html',
    styleUrls:['./puntos-venta-mobile.component.scss']
})
export class puntosVentaMobileComponent
{
    constructor(){}
}