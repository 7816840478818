<!--<div class="day-wraper">
  <div class="calendar" (click)="switchSelection()" >
    <div class="card" [ngClass]="{'selected': selected}"> 
      <div class="day" [ngClass]="{'active': selected, 'disabled': !selected}">
        <span>{{date.day}}</span>
      </div>
      <div class="month" [ngClass]="{'active': selected, 'disabled': !selected}">
        <span>{{date.month}}</span>
      </div>
    </div>
    <div class="day-name" [ngClass]="{'active': selected, 'disabled': !selected}">
      <span >{{date.dayName}}</span>
    </div>
  </div>
</div>
-->

<div #day class="new-day-wrapper" [style.color]="selected ? '#FF3880' : '#9FA5AA'" (click)="switchSelection()">
  <div class="data-day-wrapper" [style.border-color]="selected ? '#FF3880' : '#9FA5AA'" [style.background-color]="selected ? '#FFEAF1' : 'white'">
    <span class="new-day">{{date.day}}</span>
    <span class="new-month">{{date.month}}</span>
  </div>
  <div class="new-day-name">
    <span >{{date.dayName}}</span>
  </div>
</div>