import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MClient } from '@models/client.model';
import { MPerson } from '@models/person.model';
import { ApiRouterService } from '@shared/services/api-router.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { ReponseStandar } from "@models/api-models/response-standar.model";

/**
 * User API service. Provides the app with user data from the API.
 */
@Injectable({
	providedIn: 'root'
})
export class ConfigurationApiService extends ApiRouterService {

	constructor(
		private http: HttpClient,
		private authService: AuthenticationService,
	) {
		super();
	}

	// Observable for the loading state
	public dataSourceObservable: BehaviorSubject<any> = new BehaviorSubject<any>(false);
	public observableGuinda: Observable<any> = this.dataSourceObservable.asObservable();

	// Retrieve the Guinda configuration from the server
	guindaConfig(): Observable<any> {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		const httpOptions = { headers: this.httpHeaders };
		return this.http.get(`${this.URL}config/guinda`, httpOptions);
	}

	public setGuindaConfig(guinda: boolean): void {

		this.dataSourceObservable.next(guinda);

	}


	
	// Retrieve the Guinda configuration from the server by game
	guindaConfigJuego(juego_id): Observable<any> {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		const httpOptions = { headers: this.httpHeaders };
		return this.http.get(`${this.URL}config/guinda/${juego_id}`, httpOptions);
	}

	getDocumentosLegales(): Observable<any> {
		return this.http.get(`${this.URL}legal`);
	}

	getConfiguracion(id: number): Observable<any> {
		return this.http.get(`${this.URL}config/${id}`);
	}

	checkLinkChangeEmail(code: string): Observable<any> {
		const token = this.authService.loadToken();
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };

		code = encodeURIComponent(code);

		return this.http.get(`${this.URL}checklinkemail?code=${code}`, httpOptions);
	}

	checkLinkChangeBaccount(code: string): Observable<any> {
		const token = this.authService.loadToken();
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };

		code = encodeURIComponent(code);

		return this.http.get(`${this.URL}checklinkbacc?code=${code}`, httpOptions);
	}

	confirmarTransferencia(code: string): Observable<any> {
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		const httpOptions = { headers: this.httpHeaders };

		return this.http.get(`${this.URL}confirmartransferencia?code=${code}`, httpOptions);
	}
}
