import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GamesApiservice } from '@api/services/games/games-api.service';
import { EGamesId } from '@enums/common/games.enum';
import { faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BetService } from '@modules/games/bet.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ILottery } from 'src/app/interfaces/plays/play-bet.interface';
import { PlayLottery } from '../play-lottery';

@Component({
	selector: 'app-played-lottery',
	templateUrl: './played-lottery.component.html',
	styleUrls: ['./played-lottery.component.scss']
})
export class PlayedLotteryComponent implements OnInit {

	public iconStar = faStar;
	public iconCross = faTimes;
	public totalBets: number;
	public reintegro: number;
	public gameNumbers: ILottery[] = [];
	public selectedNumbers: ILottery[] = [];
	public lastNumber: ILottery;
	public loading: boolean;
	public numTickets: number;
	public url: string;
	public lottery = [];
	public date: string;
	public activeNumbers: number = 0;
	public dateObservableSuscription: Subscription;
	public desactivaAutomatico = false;

	private betSubscription: Subscription;
	public numbers = []
	public pack = undefined
	public numbersShow: { number: string, count: number }[] = [];
	public image: string = '';
	public imageMult: string = '';

	private ticketsVisible = true;

	private serviceBets: any;

	@Input() gameId: EGamesId;
	@Input() manual: boolean;
	@Input() automatic: boolean;

	constructor(

		private betService: BetService,
		private toastrService: ToastrService,
		private gamesApiService: GamesApiservice,

	) {
	}

	ngOnInit() {
		this.betSubscription = this.betService.observableBets.pipe(
			map((response: any[]) => response.map(item => item.numero))
		).subscribe({
			next: (numbers: number[]) => {
				this.numbers = numbers;
				this.updateNumbersShow(numbers);
			},
			error: (e) => console.error(e),
			complete: () => console.info('complete')
		});
		this.betService.dateObservable$.subscribe(date => {
			this.date = date;
			this.gamesApiService.sorteosAvanzadoObservable$.subscribe(res => {
				var game
				if (this.gameId == EGamesId.LOTERIA_DE_NAVIDAD || this.gameId == EGamesId.LOTERIA_DE_EL_NIÑO) {
					game = res.listadoEspecial.find((game: any) => game.juego_id === this.gameId);
				} else {
					game = res.listadoSorteos.find((game: any) => game.fecha === this.date);
				}
				const miniatura = game.miniaturas.find((miniatura: any) => miniatura.titulo.includes('mail'));
				if(miniatura == undefined)
					return;
				this.image = "http://www.loteriaelnegrito.com/uploads/" + miniatura.path + miniatura.nombre;
				const miniaturaMult = game.miniaturas.find((miniatura: any) => miniatura.titulo.includes('decimo'));
				this.imageMult = "http://www.loteriaelnegrito.com/uploads/" + miniaturaMult.path + miniaturaMult.nombre;
			});
		});

		this.betService.observableBets.subscribe(bets => { // Remove it from the service
			this.serviceBets = bets;
		});

		this.betService.packObservable$.subscribe(pack => { // Remove it from the service
			this.pack = pack;
			console.log(this.pack);
		});


	}

	deleteNumber(numberToDelete: number) {
		this.numbers = this.numbers.filter(number => number !== numberToDelete);
		this.updateNumbersShow(this.numbers); // Update numbersShow after deletion
		this.serviceBets = this.serviceBets.filter(bet => bet.numero !== numberToDelete);
		this.betService.setBets(this.serviceBets);
	}

	triggerChangeNumber() {
		this.betService.changeRandomTriggerFunction.emit();
	}

	updateNumbersShow(numbers: number[]) {
		const frequencyMap: { [key: number]: number } = {};
		numbers.forEach(number => {
			if (frequencyMap[number]) {
				frequencyMap[number]++;
			} else {
				frequencyMap[number] = 1;
			}
		});

		// Create a map of the current numbersShow for quick lookup
		const currentNumbersShowMap = new Map(this.numbersShow.map(item => [item.number, item]));

		// Update the frequency counts in place
		for (const [number, count] of Object.entries(frequencyMap)) {
			if (currentNumbersShowMap.has(number)) {
				currentNumbersShowMap.get(number).count = count;
			} else {
				this.numbersShow.push({ number, count });
			}
		}

		// Remove numbers that are no longer present
		this.numbersShow = this.numbersShow.filter(item => frequencyMap[item.number] !== undefined);
	}

	toggleTicketsVisibility() {
		this.ticketsVisible = !this.ticketsVisible;
	}

	get backgroundImage(): string {
		return this.image ? `url(${this.image})` : 'url(../../../../../../assets/images/background/empty_ticket.svg)';
	}
	get backgroundImageMult(): string {
		return this.imageMult ? `url(${this.imageMult})` : 'url(../../../../../../assets/images/background/empty_ticket.svg)';
	}
}
