import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EApiUrl } from "@enums/common/api.enum";
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn:'root'
})
export class ApiLotoUpRouterService
{
    protected URL: EApiUrl;
    protected httpHeaders: HttpHeaders;

    constructor() {

      this.URL = this.getApiUrlByEnvironment(false);
      this.httpHeaders = new HttpHeaders();

    }

    /**
     * Set the correct API url according to the current environment.
     * @param local [OPTIONAL] Indicates if the API is running in a local environment.
     *
     * @returns void
     */
    private getApiUrlByEnvironment(local?: boolean): EApiUrl {

      if (local) {

        return EApiUrl.LOTOUP_LOCAL;

      } else {

        return environment.production ? EApiUrl.LOTOUP_PROD : EApiUrl.LOTOUP_DEV;

      }

    }
}