<div class="inicio-wrapper">
    <div class="container">
        <div class="header">
            <img src="/assets/images/pages/inicio-auth/logo.svg" alt="img"/>
        </div>
        <div class="body">
            <div class="title1">
                <h4>¿A que quieres ganar hoy?</h4>
            </div>
            <div class="buttons">
                <button class="btn-acceder" routerLink="/acceso/options/acceder">Acceder</button>
                <button class="btn-register" routerLink="/acceso/options/registrate">¡Registrate!</button>
            </div>
            <div class="descubre">
                <p>Descubre las ventajas</p>
                <img src="/assets/images/pages/inicio-auth/Plane_departing.svg" alt="img"/>
            </div>
        </div>
    </div>
</div>