<div class="modal-wrapper">
	<div class="header">
		<div class="cancel" [mat-dialog-close]="false">x</div>
		<div class="icon">
			<div class="icon2">
				<img src="/assets/images/icons/seguridad/locked.svg" alt="img"/>
			</div>
		</div>
	</div>
	<div class="body">
		<div class="content">
			<div class="title">Uso de datos</div>
			<div class="text">
				Estamos muy comprometidos con la privacidad de los datos. Miloto guarda los datos de usuario, identificador de cuentas sociales y correo electrónico para identificarte en la compra y en caso de premio, incluso cuando la aplicación está cerrada o no está en uso. Por favor, acepta nuestros <a href="https://web.miloto.es/paginas/terminos-condiciones">términos y condiciones</a> y <a href="https://web.miloto.es/paginas/politica-privacidad">política de privacidad de datos</a> para continuar.
			</div>
		</div>
	</div>
	<div class="footer">
		<button class="accept" [mat-dialog-close]="true">Aceptar</button>
	</div>
</div>

