import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlankComponent } from './blank/blank.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { GlobalModule } from 'src/app/shared/modules/global.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { inicioAuthComponent } from './inicio/inicio-auth.component';
import { optionsMenuComponent } from './optionsMenu/options-menu.component';
import { changePasswordComponent } from './change-password/change-password.component';
import { loginDesktopGuard } from '@shared/guards/loginDesktop.guard';
import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login';

const routes: Routes = [
  {
    path: '',
    component: BlankComponent,
    children: [
      { path: '', redirectTo: 'inicio-lobby', pathMatch: 'full' },
      { path:'inicio-lobby', component:inicioAuthComponent,canActivate:[loginDesktopGuard] },
      { path:'options/:mode', component:optionsMenuComponent, canActivate:[loginDesktopGuard]},
      { path: 'registro', component: RegisterComponent, canActivate:[loginDesktopGuard] },
      { path: 'inicia-sesion', component: LoginComponent,canActivate:[loginDesktopGuard] },
      { path: 'recuperar-contrasena', component: RecoverPasswordComponent},
      { path:'change-password/:clave', component:changePasswordComponent },
      { path: 'register', component:inicioAuthComponent, canActivate:[loginDesktopGuard]},
      { path: 'login', component:inicioAuthComponent, canActivate:[loginDesktopGuard]},
	  { path: 'recover', component:inicioAuthComponent, canActivate:[loginDesktopGuard]},
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    GlobalModule,
    FontAwesomeModule,
	GoogleSigninButtonModule,
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule { }
