import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ReponseStandar } from "@models/api-models/response-standar.model";
import { ApiLotoUpRouterService } from "@shared/services/api-lotoup-router.service";

@Injectable({
    providedIn:'root'
})
export class LotoUpService extends ApiLotoUpRouterService
{
    constructor(private http: HttpClient) {
        super();
    }

    searchDecimo(date, number,page = 1) 
    {
        return this.http.get<ReponseStandar>(`${this.URL}miloto/stock/search?page=${page}&date=${date}&number=${number}&order_by=proximity`)
    }
}