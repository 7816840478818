import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MReservas } from "@models/reservas.model";
import { TokenJWTModel } from "@models/token-JWT.model";
import { ApiRouterService } from "@shared/services/api-router.service";
import { BehaviorSubject, Observable, pipe } from "rxjs";
import { take } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";

@Injectable({
    providedIn:'root'
})
export class ReservasService extends ApiRouterService
{
    //Reservas del usuario (Lista | Array)
    public reservasUser: MReservas[];
    private $reservasUserOb: BehaviorSubject<MReservas[]>;
    public $reservasOb: Observable<MReservas[]>;

    //Reservas del usuario cargadas
    private load: boolean = false;
    private load$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.load);
    public loadOb: Observable<boolean> = this.load$.asObservable();

    //Reserva update User
    private selectReserva: MReservas;
    private selectReserva$: BehaviorSubject<MReservas>;
    public selectReservaOb: Observable<MReservas>;

    private allset: boolean = false;
    private allset$: BehaviorSubject<boolean>;
    public allsetOb: Observable<boolean>;


    constructor(private http: HttpClient,
                private authService: AuthenticationService
        ){
            super();

            //Iniciar datos reservas usuario
            this.$reservasUserOb = new BehaviorSubject<MReservas[]>(this.reservasUser);
            this.$reservasOb = this.$reservasUserOb.asObservable();

            //Iniciar la reserva selectect
            this.selectReserva$ = new BehaviorSubject<MReservas>(this.selectReserva);
            this.selectReservaOb = this.selectReserva$.asObservable();

            this.allset$ = new BehaviorSubject<boolean>(this.allset);
            this.allsetOb = this.allset$.asObservable();
        }

    /**
     * Metodo que setea la lista de reservas del usuario logueado
     */
    listar(): void
    {
        const token = this.authService.loadToken();
        let tokDec: TokenJWTModel = this.authService.decodeToken(token);

        let header = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        let httpOptions = {headers: header};
        this.http.get(`${this.URL}reservas/${tokDec.idClient}`,httpOptions)
        .pipe(take(1))
        .subscribe((x: MReservas[])=>{
            this.reservasUser = x;
            this.$reservasUserOb.next(x);
            this.load=true;
            this.load$.next(true);
        });
    }

    /**
     * Metodo para setear una reserva
     */
    set_reservaUser(reserva: MReservas)
    {
        this.reservasUser.unshift(reserva);
        this.$reservasUserOb.next(this.reservasUser);
    }

    set_loadtime(load: boolean)
    {
        this.load=load;
        this.load$.next(load);
    }

    set_allset(allset: boolean)
    {
        this.allset=allset;
        this.allset$.next(allset);
    }

    /**
     * Metodo para actualizar elmento de la lista
     */
    update_reserva_item(reserva: MReservas)
    {
        let index= this.reservasUser.findIndex(x=>x.reserva_id==reserva.reserva_id);
        this.reservasUser[index] = reserva;
        this.$reservasUserOb.next(this.reservasUser);
    }

    /**
     * Metodo para remover una reserva de la lista
     */
    remobeReserva(reserva: MReservas)
    {
        let index= this.reservasUser.findIndex(x=>x.reserva_id==reserva.reserva_id);
        this.reservasUser.splice(index,1);
        this.$reservasUserOb.next(this.reservasUser);
    }

    /**
     * Metodo para introducir la reserva seleccionada
     */
    set_SelectReserva(reserva: MReservas)
    {
        this.selectReserva=reserva;
        this.selectReserva$.next(reserva);
    }

    /**
     * Devuelve una reserva en concreto
     * @param reserva_id
     * @returns Observable<Object>
     */
    detalle(reserva_id: number)
    {
        const token = this.authService.loadToken();

        let header = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        let httpOptions = {headers: header};
        return this.http.get(`${this.URL}reservas/detalle/${reserva_id}`,httpOptions);
    }

    /**
    * Crea una reserva sobre un numero
    * @param reserva_id
    * @returns Observable<Object>
    */
    crear(reserva: MReservas)
    {
        const token = this.authService.loadToken();
        let tokDec: TokenJWTModel = this.authService.decodeToken(token);

        let header = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        let httpOptions = {headers: header};
        let params = JSON.stringify(reserva);
        return this.http.post(`${this.URL}reservas/${tokDec.idClient}/insertar`,params,httpOptions);
    }

    /**
    * Actualiza un reserva
    * @param reserva_id
    * @returns Observable<Object>
    */
    update(reserva: MReservas)
    {
        const token = this.authService.loadToken();
        let tokDec: TokenJWTModel = this.authService.decodeToken(token);

        let header = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });

        let httpOptions = {headers: header};
        let params = JSON.stringify(reserva);
        return this.http.post(`${this.URL}reservas/editar/${reserva.reserva_id}`,params,httpOptions);
    }

    /**
    * Borra una reserva
    * @param reserva_id
    * @returns Observable<Object>
    */
    delete(reserva_id: number)
    {
        const token = this.authService.loadToken();
        let tokDec: TokenJWTModel = this.authService.decodeToken(token);

        let header = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });

        let httpOptions = {headers: header};
        return this.http.get(`${this.URL}reservas/delete/${reserva_id}`,httpOptions);
    }

    /**
    * Comprar decimos de una reserva reserva
    * @returns Observable<Object>
    */
    comprar(reserva: MReservas)
    {
        const token = this.authService.loadToken();
        let tokDec: TokenJWTModel = this.authService.decodeToken(token);

        let header = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });

        let httpOptions = {headers: header};

        let params = JSON.stringify(reserva);

        return this.http.post(`${this.URL}reservas/comprar`,params,httpOptions);
    }


    /**
     * Add invitado a una reserva
     */
    add_invitado(code_hash: string)
    {
        const token = this.authService.loadToken();
        let tokDec: TokenJWTModel = this.authService.decodeToken(token);

        let header = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });

        let httpOptions = {headers: header};

        return this.http.get(`${this.URL}reservasInvitados/add/${code_hash}`,httpOptions);
    }

    /**
     * Elimina a un invitado de una reserva
     */
    delete_invitado(idInvitad: number)
    {
        const token = this.authService.loadToken();
        let tokDec: TokenJWTModel = this.authService.decodeToken(token);

        let header = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });

        let httpOptions = {headers: header};

        return this.http.get(`${this.URL}reservasInvitados/delete/${idInvitad}`,httpOptions);
    }

    getNumerosDisponibles(reserva: MReservas, sorteo: any, page: number, busqueda: string, tipo: String) {
        const token = this.authService.loadToken();

        let header = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        let httpOptions = {headers: header};
        let url = `${this.URL}stock/reservastock?cantidad=${reserva.cantidad_reservada}&juego=${reserva.juego_id}&sorteo=${sorteo.numero}&page=${page}&tipo=${tipo}`;
        if (busqueda != undefined && busqueda != "") {
          url += `&search=${busqueda}`;
        }
        return this.http.get(url, httpOptions);
    }

    getFechaCompra() {
        const token = this.authService.loadToken();
        let header = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        let httpOptions = {headers: header};
        let url = `${this.URL}reservas/getfechalimite`;
        return this.http.get(url, httpOptions);
    }

	getLimite(tipo: String) {
		const token = this.authService.loadToken();

        let header = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        let httpOptions = {headers: header};
        let url = `${this.URL}reservas/limite?tipo=${tipo}`;
        return this.http.get(url, httpOptions);
	}
}
