import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { Observable, from } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class azureConfigService {

	private app: IPublicClientApplication;

	private isIE =
	window.navigator.userAgent.indexOf('MSIE ') > -1 ||
	window.navigator.userAgent.indexOf('Trident/') > -1;

	constructor(private authService: MsalService) {
		this.initAuth();
	}

	private initAuth() {
		// Initialize your app with default configuration
		this.app = new PublicClientApplication({
			auth: {
				clientId: 'e91ade2a-f645-4cfe-bd6c-e61b5a710b2c',
				authority: 'https://login.microsoftonline.com/c3b33ecd-9c1d-4e8e-99c1-aa308d7234c3',
				redirectUri: (environment.production == true ? 'https://web.miloto.es/cliente/loteriaempresas/auth' : 'http://localhost:4200/cliente/loteriaempresas/auth'),
			},
			cache: {
				cacheLocation: BrowserCacheLocation.LocalStorage,
				storeAuthStateInCookie: this.isIE,
			},
			system: { },
		});
	}

	// Update authority and redirect URI dynamically
	updateAuthConfig(authority: string, redirectUri: string) {
		this.app = new PublicClientApplication({
			auth: {
				clientId: 'e91ade2a-f645-4cfe-bd6c-e61b5a710b2c',
				authority: 'https://login.microsoftonline.com/' + authority,
				redirectUri: (environment.production == true ? 'https://web.miloto.es/cliente/loteriaempresas/auth' : 'http://localhost:4200/cliente/loteriaempresas/auth'),
			},
			cache: {
				cacheLocation: BrowserCacheLocation.LocalStorage,
				storeAuthStateInCookie: this.isIE,
			},
			system: { },
		});
	}

	async login(): Promise<Observable<void>> {
		await this.app.initialize();

		const request: RedirectRequest = {
			scopes: ['user.read'],
		};

		// Use the updated app instance for the redirect request
		return from(this.app.loginRedirect(request));
	}
}