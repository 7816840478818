import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MPlay } from '@models/api-models/plays-api.models';

import { modalYesNoComponent } from '@shared/components/modals/modalYesNo/modalYesNo.component';
import { modalShareComponent } from '@shared/components/modals/modalShare/modalShare.component';
import { modalInfoComponent } from '@shared/components/modals/modalInfo/modalInfo.component';
import { MatDialog } from '@angular/material/dialog';
import { PlaysAPIService } from '@api/services/games/plays-api.service';
import { bottomSheetRegalarComponent } from '@shared/components/bottomSheet/bottomSheetRegalar/bottomSheetRegalar.component';
import { bottomSheetAbonoComponent } from '@shared/components/bottomSheet/bottomSheetAbono/bottomSheetAbono.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { EDevice } from '@enums/common/sizes.enum';
import { now } from 'moment';
import { ToastrService } from "ngx-toastr";
import { logEvent } from '@firebase/analytics';
import { FireBase } from '@classes/fireBase';
import { FiltrosService } from '@api/services/filtros.service';
import * as moment from 'moment';
import { BottomSheetRepetirJugadaComponent } from '@shared/components/bottomSheet/bottomSheetRepetirJugada/bottomSheetRepetirJugada.component';
import { modalAlertaComponent } from '@shared/components/modals/modalAlerta/modalAlerta.component';
import { BetService } from '@modules/games/bet.service';
import { UserApiService } from '@api/services/user.service';
import { ConfigurationApiService } from '@api/services/configuration.service';
import { Mygroup } from '@models/group.model';
import { GroupService } from '@api/services/group.service';

@Component({
	selector: 'app-play',
	templateUrl: './play.component.html',
	styleUrls: [
		'./play.component.scss',
	]
})
export class PlayComponent extends FireBase {

	selected = false;

	@Input() play: MPlay;
	@Input() last;
	@Input() jugada: any;
	@Input() device: any;
	@Input() abono: boolean = false
	@Input() grupo: Mygroup | null = null;
	@Output() dropAbono: EventEmitter<MPlay> = new EventEmitter()

	public precioGuindaFinal: number;
	public saldo: number;
	public guindaConfig: boolean = false;

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private playsApiService: PlaysAPIService,
		private bottomSheetRegalar: MatBottomSheet,
		private bottomSheetAbono: MatBottomSheet,
		private toastrService: ToastrService,
		private filterService: FiltrosService,
		private sheet: MatBottomSheet,
		private betService: BetService,
		private userService: UserApiService,
		private configService: ConfigurationApiService,
		private routerActive: ActivatedRoute,
		private grupoService: GroupService
	) {
		super();

		this.userService.usuarioObservable.subscribe(res => this.saldo = res.client.getSaldoTotal());
		this.configService.observableGuinda.subscribe(x => {
			this.guindaConfig = x;
		});
	}

	styleState(): object {

		switch (this.play.state) {
			// En Juego
			case 1: {
				return { color: '#1FC9FF' };
			}
			// Sorteada
			case 2: {
				return { color: '#FF1F1F' };
			}
			// Premio
			case 3: {
				return { color: '#00BA88' };
			}
		}
		return {};
	}

	openOptions() {
		this.selected = !this.selected;
	}

	/**
	 * Redirect to play detail
	 * @returns void 
	 */
	public detailPlay(play: MPlay): void {

		if (this.grupo == null) {
			if (this.play.producto)
				this.infoParticipacion()
			else {
				//this.router.routeReuseStrategy.shouldReuseRoute = function () { return true; }
				this.router.navigate(['/cliente/jugadas', play.id]);
			}
		}
		else {
			const url = this.router.url.split('/')
			this.router.navigate(['/cliente/mis-grupos/detalles', url[4], url[5], 'jugada', play.id]);
		}
	}

	public abonarse() {
		let alerta;
		let day = new Date(this.play.dateSorteo);
		if (this.play.idGame == 7 && day.getDay() != 4) {
			const texto = 'La suscripción consiste en que cada lunes se comprara tu jugada de forma automática para toda la semana con los mismos números. Además, también te guardaremos el número para Navidad y El Niño. Mucha suerte';
			alerta = this.dialog.open(modalYesNoComponent, {
				panelClass: 'modal-custom',
				data: {
					title: '¡Atencion vas a suscribirte!',
					texto,
					buttonConfirm: 'Abonarme',
					btnConfirmColor: 'blue',
					buttonCancel: 'Cancelar',
					showIcon: false,
					showOptionCheck: true
				}
			});
		}
		else {
			const texto = 'La suscripción consiste en que cada lunes se comprara tu jugada de forma automática para toda la semana con los mismos números. Mucha suerte';
			alerta = this.dialog.open(modalYesNoComponent, {
				panelClass: 'modal-custom',
				data: {
					title: '¡Atencion vas a suscribirte!',
					texto,
					buttonConfirm: 'Abonarme',
					btnConfirmColor: 'blue',
					buttonCancel: 'Cancelar',
					showIcon: false,
					showOptionCheck: true
				}
			});
		}

		alerta.afterClosed().subscribe(res => {
			if (res) {
				if (this.grupo == null) {
					this.playsApiService.abonoPlay(this.play.id).subscribe(res => {
						this.play.abono = res;
						this.filterService.setUpdateAbonos(true);
	
						let paramFireBase = {
							ITEM_NAME: "btnSuscribe"
						}
						logEvent(this.analytics, "CLICK_ON", paramFireBase)
					});
				}
				else {
					this.grupoService.abonoPlay(this.play.id, this.grupo.cliente_id).subscribe(res => {
						this.play.abono = res;
						this.filterService.setUpdateAbonos(true);

						let paramFireBase = {
							ITEM_NAME: "btnSuscribe"
						}
						logEvent(this.analytics, "CLICK_ON", paramFireBase)
					});
				}
			}
		});
	}

	public desabono() {
		if (this.grupo == null) {
			this.playsApiService.desabonoPlay(this.play.id).subscribe(res => {
				this.play.abono = null;
				if (!this.abono)
					this.filterService.setUpdateAbonos(true)
				else {
					this.filterService.setUpdateJugadas(true)
					this.dropAbono.emit(this.play)
				}
	
	
				this.toastrService.success("Abono cancelado", "", {
					positionClass: 'toast-bottom-center',
				});
			});
		}
		else {
			this.grupoService.desabonoPlay(this.play.id, this.grupo.cliente_id).subscribe(res => {
				this.play.abono = null;
	
				if (!this.abono) {
					this.filterService.setUpdateAbonos(true)
				}
				else {
					this.filterService.setUpdateJugadas(true)
					this.dropAbono.emit(this.play)
				}
	
				this.toastrService.success("Abono cancelado", "", {
					positionClass: 'toast-bottom-center',
				});
			});
		}
	}

	public repeat() {
		this.sheet.open(BottomSheetRepetirJugadaComponent, {
			data: { play: this.play, modoGrupo: this.grupo == null } 
		});
	}

	public compartir() {

		this.selected = false;
		this.playsApiService.sharePlay(this.play.id).subscribe(res => {
			const modalClass = this.device === 'mobile' ? 'modal-custom' : 'share-modal';

			let compartir = this.dialog.open(modalShareComponent, {
				panelClass: modalClass,
				data: {
					title: 'Compartir Jugada',
					link: res.link,
					showOptionCheck: false
				}
			});
			compartir.afterClosed().subscribe(res => {
				if (res) {
				}
			});
		});

	}

	isSorteado() {
		let fechaSorteo = moment(this.play.dateSorteo)

		if (fechaSorteo.diff(now()) <= 0 && this.play.state != 3) {
			this.play.state = 2;
			return true
		}

		return false;
	}

	openRegalarJugada() {

		this.bottomSheetRegalar.open(bottomSheetRegalarComponent, {
			// hasBackdrop: true,
			// backdropClass: 'backdropBottomSheet',
			// panelClass: 'bottomSheetContainerJugadaRegalada',
			data: { jugada_id: this.play.id }
		});
	}

	openGuindaJugada() {
		let texto = "Vas a asegurar este boleto y sus jugadas por ";
		if (!this.play.multiple) {
			this.precioGuindaFinal = (this.play.guinda_price * this.play.betsNumbers.length);
		}
		else {
			switch (this.play.idGame) {
				case 1:
					this.precioGuindaFinal = this.betService.betArray[this.play.betsNumbers[0].length - 5] * this.play.guinda_price;
					break;
				case 3: 
					let xAxis = this.play.valueStars.length - 2;
					let yAxis = this.play.betsNumbers[0].length - 5;
					this.precioGuindaFinal = this.betService.betEuromillonesTable[xAxis][yAxis] * this.play.guinda_price;
					break;
				case 4:
					this.precioGuindaFinal = this.betService.betArray[this.play.betsNumbers[0].length - 5] * this.play.guinda_price;
					break;
				case 5:
					this.precioGuindaFinal = this.betService.betGordoArray[this.play.betsNumbers[0].length - 5] * this.play.guinda_price;
					break;
				case 11:
					let dobles = 0; let triples = 0;
					for (let fila of this.play.betsNumbers[0]) {
						if (fila.length == 2) {
							dobles++;
						}
						if (fila.length == 3) {
							triples++;
						}
						this.precioGuindaFinal = (Math.pow(2, dobles) * Math.pow(3, triples)) * this.play.guinda_price;
					}
					break;
				default:
					break; 
			}
		}
		texto += this.precioGuindaFinal + "€. Y si te toca LaGuinda te devolverá los impuestos. ¿Estas seguro?";
		let dialogAlert = this.dialog.open(modalYesNoComponent, {
			panelClass: 'modal-custom',
			data: {
				title: '¿Asegurar tu boleto?',
				texto,
				buttonConfirm: 'Asegurar',
				buttonCancel: 'Cancelar',
				buttonConfirmColor: "blue",
				showIcon: false,
				showOptionCheck: false
			}
		});

		dialogAlert.afterClosed().subscribe(res => {
			if (res) {
				this.playsApiService.asegurarJugada(this.play.id).subscribe((response: any) => {
					if (response.data) {
						this.toastrService.success('Se ha asegurado tu boleto con LaGuinda');
						this.userService.setSaldo(this.saldo - this.precioGuindaFinal);
						this.play.guinda = true;
					} else {
						this.toastrService.error('Ha ocurrido un error al asegurar tu boleto');
					}
				});
			}
		});
	}

	openAbono() {

		if (this.play.idGame == 1 && Number(this.play.price) < 1) {
			this.infoAbonoBonolotoMal();
			return;
		}


		this.bottomSheetAbono.open(bottomSheetAbonoComponent, {
			hasBackdrop: true,
			backdropClass: 'backdropBottomSheet',
			panelClass: 'bottomSheetContainerJugadaRegalada',
			data: {}
		});
	}

	public infoParticipacion(): void {
		this.dialog.closeAll();
		let dialogAlert = this.dialog.open(modalAlertaComponent, {
			panelClass: 'modal-custom',
			data: {
				title: 'Información de la Participación',
				texto: 'Los resultados de la participación se enviarán por correo en el momento en el que se anuncien los resultados.',
				buttonConfirm: 'Continuar',
				buttonCancel: 'Continuar',
				buttonConfirmColor: "blue",
				showIcon: false,
				showOptionCheck: false
			}
		});
	}
	public infoAbonoBonolotoMal(): void {
		this.dialog.closeAll();
		let dialogAlert = this.dialog.open(modalAlertaComponent, {
			panelClass: 'modal-custom',
			data: {
				title: 'Abono Bonoloto',
				texto: 'Solo es posible abonar jugadas de Bonoloto con un precio mínimo de 1€',
				buttonConfirm: 'Continuar',
				buttonCancel: 'Continuar',
				buttonConfirmColor: "blue",
				showIcon: false,
				showOptionCheck: false
			}
		});
	}


}
