import { Component } from "@angular/core";

@Component({
    selector:'app-direccion-compra-mobile',
    templateUrl:'./direccion-compra-mobile.component.html',
    styleUrls:['./direccion-compra-mobile.component.scss']
})
export class direccionCompraMobileComponent
{
    constructor(){}
}