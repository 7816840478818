<div class="permitir-recoger-core-wrapper">

    <ng-container *ngIf="device === devices.DESKTOP">

        <app-direccion-compra-desktop></app-direccion-compra-desktop>

    </ng-container>
    <ng-container *ngIf="device === devices.MOBILE">

        <app-direccion-compra-mobile></app-direccion-compra-mobile>

    </ng-container>

</div>