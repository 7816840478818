<div class="recover-wrapper">

	<div class="lock-wrapper">

		<div class="lock-circle">
			<img class="lock-img" src="/assets/images/icons/loginYregistro/lock.svg" alt="Lock">
		</div>

	</div>



	<div class="title">¿Tienes problemas para entrar?</div>

	<form [formGroup]="recoverPasswordForm" id="recover-form" (ngSubmit)="recoverPassword()"
		class="recover-form">

		<mat-form-field appearance="outline" class="form-input email">
			<mat-label>Email</mat-label>
			<input matInput type="email" placeholder="Email" formControlName="email" required>
			<mat-hint style="width: 100%; line-height: 12px; font-size: smaller;">Introduce tu correo electrónico y te enviaremos un enlace para que vuelvas a entrar en tu cuenta.</mat-hint>
		</mat-form-field>

		<span class="error-message"
			*ngIf="recoverPasswordMessage && (recoverPasswordMessage.type == 'warning' || recoverPasswordMessage.type == 'error')">{{
			recoverPasswordMessage.message }}</span>

		<button mat-raised-button color="miloto" type="submit" [disabled]="!recoverPasswordForm.valid || loadingRecover"
			class="login-button">
			<span *ngIf="!loadingRecover">Enviar email</span>
			<mat-icon *ngIf="loadingRecover">
				<mat-spinner diameter="20"></mat-spinner>
			</mat-icon>
		</button>

	</form>

	<div class="notice">
		<a class="text-info">
			¿Has recordado la contraseña?
			<strong (click)="changeAction('recover')" class="miloto-primary">
				<u>Inicia sesión</u>
			</strong>
		</a>
	</div>

</div>