<div class="recover-password-wrapper">

    <div class="header">
  
      <div class="logo">
  
        <app-auth-header [title]="'Cambiar Contraseña'"></app-auth-header>
  
      </div>
    </div>
  
    <div class="body">
  
      <div class="title">Introduce tu nueva contraseña.</div>
  
      <form [formGroup]="changePasswordForm" id="recover-password-form" (ngSubmit)="changePassword()" class="recover-password-form">

		<mat-form-field appearance="outline" class="form-input formPassword" [ngClass]="{invalidPass: passwordInvalid || first}">
			<mat-label>Contraseña</mat-label>
			<input matInput placeholder="Nueva Contraseña" [type]="hide ? 'password' : 'text'" formControlName="nuevoPassword"
				required (input)="nofirst()">
			<button matSuffix type="button" (click)="hidePass()" class="button-pass">
				<img *ngIf="!hide" src="/assets/images/icons/loginYregistro/eye-off.svg" alt="Ocultar contraseña">
				<img *ngIf="hide" src="/assets/images/icons/loginYregistro/eye-show.svg" alt="Mostrar contraseña">
			</button>
			<mat-error *ngIf="passwordInvalid" style="line-height: 14px;">La contraseña debe ser de al menos 8 caracteres y incluir al menos una mayúscula, minúscula, número y caracter especial.</mat-error>
			<mat-hint *ngIf="first" style="line-height: 14px;">La contraseña debe ser de al menos 8 caracteres y incluir al menos una mayúscula, minúscula, número y caracter especial.</mat-hint>
		</mat-form-field>
        
		<mat-form-field appearance="outline" class="form-input formPassword">
			<mat-label>Contraseña</mat-label>
			<input matInput placeholder="Repetir Contraseña" [type]="hideRepeat ? 'password' : 'text'" formControlName="repeatPassword"
				required (input)="nofirst()">
			<button matSuffix type="button" (click)="hideRePass()" class="button-pass">
				<img *ngIf="!hideRepeat" src="/assets/images/icons/loginYregistro/eye-off.svg" alt="Ocultar contraseña">
				<img *ngIf="hideRepeat" src="/assets/images/icons/loginYregistro/eye-show.svg" alt="Mostrar contraseña">
			</button>
		</mat-form-field>
  
        <div *ngIf="changePasswordMessage" [ngClass]="{
          'box-success': changePasswordMessage.type === 'success',
          'box-warning': changePasswordMessage.type === 'warning',
          'box-error': changePasswordMessage.type === 'error'
        }">
          <span>{{ recoverPasswordMessage.message }}</span>
        </div>
  
        <div class="actions">
  
          <button class="recover-password-submit" type="submit" mat-raised-button color="primary">Cambiar contraseña</button>
  
        </div>
  
      </form>
  
    </div>
  
  </div>