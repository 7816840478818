import { VIRTUAL_SCROLL_STRATEGY } from "@angular/cdk/scrolling";
import { AfterViewChecked, Component, Inject, Renderer2 } from "@angular/core";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { CustomVirtualScroll } from "@classes/CustomVritualScoll";
import { MPlay } from "@models/api-models/plays-api.models";
import { PedidosService } from "@api/services/pedidos.service";

@Component({
    selector:'app-bottomSheetTrazaJugada',
    templateUrl:'./bottomSheetTrazaJugada.component.html',
    styleUrls:['./bottomSheetTrazaJugada.component.scss']
    ,providers:[{provide:VIRTUAL_SCROLL_STRATEGY,useClass:CustomVirtualScroll}]
})
export class bottomSheetTrazaJugadaComponent
{
    listaSeguimiento = [];
    jugada : MPlay;

    constructor(private bottomSheetTrazaJugada: MatBottomSheetRef<bottomSheetTrazaJugadaComponent>,
                private pedidoService: PedidosService,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data: {jugada: MPlay},
    ){
        this.jugada = data.jugada
        this.loadSteps()
    }

    loadSteps()
    {
        this.pedidoService.getSeguimientoPedido(this.jugada.deliveryId,this.jugada.id).subscribe(res => {
            if(res.status == 200){
                this.listaSeguimiento = res.data
				if(this.jugada.guinda){
					this.listaSeguimiento.push({'fecha': this.jugada.guinda_info.asegurada_el, 'url': this.jugada.guinda_info.url_recibo, 'nombre': 'Seguro guinda'});
				}
			}
        })
    }

    goToLink(url: string)
    {
        window.open(url, "_blank");
    }

    isPedidoSeguimiento(seguimiento)
    {
        var aux = false
        
        if(seguimiento.url.search("mrw") >= 0)
            aux = true

        return aux
    }

    dimiss()
    {
        this.bottomSheetTrazaJugada.dismiss();
    }
}