import { BetService } from '@modules/games/bet.service';
import { knuthShuffle } from 'knuth-shuffle';
import { IBet, IBetOptions, ILottery } from 'src/app/interfaces/plays/play-bet.interface';

export abstract class PlayLottery{

    public bets: IBet[];
    public lottery: ILottery[];
    public betsChecked: IBet[];
    public options: IBetOptions;

    constructor(
    ) { }

    public emitPlay(): void{}

    public createRandomBet(): IBet{

      const newBet: IBet = {
        id: this.bets.length,
        label: this.bets.length + 1,
        mainMatrix: this.initGameMatrix(this.options.numberBetOptions.rows, this.options.numberBetOptions.columns, this.options.numberBetOptions.firstItem, this.options.numberBetOptions.itemsPerBet, true),
        extraMatrix: this.initGameMatrix(this.options.extraOptions.rows, this.options.extraOptions.columns, this.options.extraOptions.firstItem, this.options.extraOptions.itemsPerBet, true),
        numbersChecked: this.createRandomValues('numberBetOptions'),
        extraChecked: [],
        currentAction: '',
        leftCheckedNumbers: 0,
        leftCheckedExtra: 0,
      };

      switch (this.options.idGame){

        case 3:

          newBet.extraChecked = this.createRandomValues('extraOptions');
          break;

        case 4:

          if (newBet.id == 0) {

            newBet.extraChecked = this.createRandomValues('extraOptions');

          }
          break;

        default:

          newBet.extraChecked = [];
          break;

      }
      return newBet;

    }

    public clearBets(): void{}

    protected createRandomValues(optionsType: string): Array<number>{

      return knuthShuffle(Array.from({ length: this.options[optionsType].itemsPerBet }, (_, i) => i + 1)).slice(0, this.options[optionsType].maxItemsPerBet);

    }

    protected clearBet(bet: IBet): void{}

  /**
   * Method to initialize a matrix for a game.
   * @param rows Number of rows in the matrix.
   * @param columns Number of columns in the matrix.
   * @param initialNumber [OPTIONAL] First number of the matrix. Default: 1.
   * @param limitNumber [OPTIONAL] Last number to be setted. Default: false.
   * @param asColumn [OPTIONAL] Fills the matrix vertically. Default: false.
   *
   * @return Matrix
   */
  protected initGameMatrix(rows: number, columns: number, initialNumber: number, limitNumber: number | boolean = false, asColumn: boolean = false): Array<Array<number>>{

    let response: Array<Array<number | boolean>>;

    if (!asColumn){

      const rowArray = new Array(rows);
      for (let i = 0; i < rowArray.length; i++) {

        const columnArray = new Array(columns);
        for (let j = 0; j < columnArray.length; j++) {

          if (limitNumber) {

            columnArray[j] = initialNumber <= limitNumber ? initialNumber++ : null;

          } else {

            columnArray[j] = initialNumber++;

          }

        }

        rowArray[i] = columnArray;

      }

      response = rowArray;

    } else {

      const rowArray = new Array(rows);
      for (let i = 0; i < rowArray.length; i++) {

        let currentNumber = initialNumber + i;

        const columnArray = new Array(columns);
        for (let j = 0; j < columnArray.length; j++) {

          if (limitNumber) {

            columnArray[j] = currentNumber <= limitNumber ? currentNumber : null;
            currentNumber += rowArray.length;

          } else {

            columnArray[j] = currentNumber;
            currentNumber += rowArray.length;

          }

        }

        rowArray[i] = columnArray;

      }

      response = rowArray;

    }

    return response as Array<Array<number>>;

  }

  protected removeItemFromArray = (arr, item) => {

    return arr.filter( e => e !== item);

  }

}
