<div class="header-mobile-wrapper">

    <div class="d-flexbox">
      <div class="btn-accion" (click)="back(true)">
        <img src="/assets/images/icons/header/arrow-left.svg" alt="img"/>
      </div>

      <div class="title">
        <h4>{{title}}</h4>
      </div>
      
    </div>
</div>