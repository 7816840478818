import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HeaderService } from "@api/services/header.service";
import { CommonModule } from "@angular/common";
import { Output, EventEmitter } from '@angular/core';

@Component({
    selector:'auth-modal',
    templateUrl:'./authModal.component.html',
    styleUrls:['./authModal.component.scss']
})
export class authModalComponent
{
    public type: string = 'register';
	public title: string = 'Registro';

	@Output() newItemEvent = new EventEmitter<string>();

    constructor(
		public dialogRef: MatDialogRef<authModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {type: string},
                headerService: HeaderService
    ){
        this.type = data.type

		this.changeTitle()
    }

	close(){this.dialogRef.close();}


	closeModal(close: boolean){
		if(close)
			this.dialogRef.close();
	}
	
	changeTitle(){
		if(this.type == 'login')
			this.title = 'Inicio de Sesión';
		if(this.type == 'register')
			this.title = 'Registro';
		if(this.type == 'recover')
			this.title = 'Recuperar Contraseña';
	}


	changeAction(action: string){
		this.type = action;
		this.changeTitle();
	}


}