export enum EAuthenticationAvailableOptions {
  LOGIN = 'login',
  REGISTER = 'sign',
  LOGOUT = 'logout',
  SOCIAL_LOGIN = 'socialLogin',
  JWT_LOGIN = 'jwt',
  GOOGLE_LOGIN = 'google',
  FACEBOOK_LOGIN = 'facebook'
}


