import { Component, OnDestroy, OnInit } from '@angular/core';
import { faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BetService } from '@modules/games/bet.service';
import { ToastrService } from 'ngx-toastr';
import { IBet } from 'src/app/interfaces/plays/play-bet.interface';
import { PlayBet } from '../play-bet';
import { EGamesId } from '@enums/common/games.enum';
import { InvitadoJugadaGratis } from '@models/invitado_jugadaGratis.model';
import { playFreeService } from '@api/services/playFree.service';
import { Observable } from 'rxjs';
import { ApplicationStateService } from '@shared/services/application-state.service';
import { EDevice, EDeviceSize } from '@enums/common/sizes.enum';
import { take } from 'rxjs/operators';
import { ColorGenerator } from "@classes/ColorGenerator";
import { UserApiService } from '@api/services/user.service';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { AuthenticationService } from '@api/services/authentication.service';
import { SocialUser } from "@abacritt/angularx-social-login";;
import { bottomSheetAgendaComponent } from '@shared/components/bottomSheet/bottomSheetAgenda/bottomSheetAgenda.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

export interface Contact {
	name: string;
	number: string;
	initials: string;
}

@Component({
	selector: 'app-automatic-play-bet',
	templateUrl: './automatic-play-bet.component.html',
	styleUrls: ['./automatic-play-bet.component.scss']
})
export class AutomaticPlayBetComponent extends PlayBet implements OnDestroy, OnInit {

	public device: EDevice;
	public colorGenerator: ColorGenerator;
	public contactName: string;

	public socialUser: SocialUser;
	public iconApple = faApple;
	public iconAndroid = faAndroid;
	public iconStar = faStar;
	public iconCross = faTimes;
	public totalBets: number;
	public reintegro: number;
	public freeBet: boolean;
	public invitadoFreePlay: InvitadoJugadaGratis = new InvitadoJugadaGratis();
	public numberBets = 0;
	public manual = false;
	public desactivaAutomatico = false;
	public configuracionBeetFree: { max_jugadas: number, max_personas: number } = null
	public maxNumBets: Number = null

	stateCtrl = new FormControl();
	filteredStates: Observable<Contact[]> = new Observable<Contact[]>();

	contact: Contact;
	states: Contact[] = [];

	constructor(
		private applicationStateService: ApplicationStateService,
		private betService: BetService,
		private toastrService: ToastrService,
		private playFreeService: playFreeService,
		private userService: UserApiService,
		private auth: AuthenticationService,
		private bottomSheetAgenda: MatBottomSheet,
	) {

		super();
		this.freeBet = false;

		this.betService.observablenumberBets.subscribe((res: any) => { this.numberBets = res; });
		this.betService.manualModeObservable$.subscribe((res: any) => {
			this.manual = res;

			if (!this.manual && this.bets.length > 0) {
				this.clearBets()
				this.bets = []
			}

		});

		this.betService.desactivaAutomaticoModeObservable$.subscribe((res: any) => { this.desactivaAutomatico = res; });
		this.playFreeService.contactoObservable.subscribe((res: any) => { 
			this.contact = res; 
			if(this.contact){
				this.invitadoFreePlay = {
					nombre : this.contact.name,
					mensaje : '',
					movil : parseInt(this.contact.number)
				}
			}

		});
		this.options = this.betService.options;
		this.desactivaAutomatico = this.betService.desactivaAutomaticoMode;
		this.totalBets = 0;
		this.bets = [];

		if (document.documentElement.clientWidth < EDeviceSize.MINDESKTOP)
			this.device = EDevice.MOBILE;
		else
			this.device = EDevice.DESKTOP;

		this.colorGenerator = new ColorGenerator();
	}

	private _filterStates(value: string): Contact[] {
		const filterValue = value.toLowerCase();

		return this.states.filter(state => state.name.toLowerCase().includes(filterValue));
	}

	ngOnInit(): void {
		this.betService.freeBetObservable$.subscribe((res: any) => {
			this.freeBet = res;
			this.userService.usuarioObservable.subscribe(res => { this.contactName = res.name.charAt(0).toUpperCase() + res.surname?.charAt(0).toUpperCase() });
			if (res) {
				this.playFreeService.getConfiguracionJugadaGratis().pipe(take(1)).subscribe((playFreeResponse: any) => {
					if (playFreeResponse.status == 200) {
						if (this.configuracionBeetFree == null)
							this.configuracionBeetFree = playFreeResponse.data

						this.applyRulesFreeBets()

					}
					else
						this.freeBet = false
				})
			}
			else
				this.configuracionBeetFree = null

		});

		switch (this.options.idGame){
			case EGamesId.BONOLOTO:
				this.incrementBet();
				this.incrementBet();
				break;
			case EGamesId.QUINIELA:
				this.incrementBet();
				this.incrementBet();
				break;
			default:
				this.incrementBet();
				break;
			
		}

	}

	private applyRulesFreeBets() {

		if (this.betService.options.idGame === 1)
			this.configuracionBeetFree.max_jugadas += 1;

		this.maxNumBets = this.configuracionBeetFree.max_jugadas

		if (this.betService.bets.length != this.bets.length) {
			this.clearBets()
			this.bets = []
			this.bets = this.betService.bets
			this.betService.setBets(this.bets)
			this.numberBets = this.betService.bets.length
		}

		if (this.betService.bets.length > this.configuracionBeetFree.max_jugadas) {
			let subArray = this.bets.slice(0, this.configuracionBeetFree.max_jugadas)
			this.clearBets()
			this.bets = []
			this.bets = subArray
			this.betService.setBets(subArray)
			if (this.bets.length == 0) {
				this.reintegro = -1;
			}
		}

	}

	public decrementBet(): void {

		if (!this.manual) {
			if (this.bets.length > 0) {

				this.bets.pop();
				this.betService.setBets(this.bets);
				if (this.bets.length == 0) {

					this.reintegro = -1;
				}

			}
		}

	}

	public incrementBet(): void {

		if (!this.manual) {

			let limite = this.freeBet ? this.configuracionBeetFree.max_jugadas : this.options.betsAtOnce

			if (this.bets.length < limite) {

				this.bets.push(this.createRandomBet());
				var aux = []
				if (this.bets.length == 1 && (this.options.idGame == 4)) {

					aux = this.bets[0].extraChecked;
					this.reintegro = this.bets[0].extraChecked[0];
					this.bets[0].extraChecked = [];

					if (this.reintegro > -1)
						this.betService.setReintegro(this.reintegro)
				}

				this.betService.setBets(this.bets);
				if (this.bets.length == 1 && (this.options.idGame == 4)) {
					this.bets[0].extraChecked = aux;
				}

			} else {

				this.toastrService.error('Límite de apuestas alcanzado!');

			}

		}
	}

	protected clearAutoBet(idx: number): void {

		this.bets.splice(idx, 1);
		let i = 0;
		this.bets.forEach(bet => {
			bet.id = i;
			bet.label = i + 1;
			i++;
		});

		this.betService.setBets(this.bets);
		if (this.bets.length == 0) {

			this.reintegro = -1;

		}
	}

	protected test(): void {
		const agenda = this.bottomSheetAgenda.open(bottomSheetAgendaComponent, {
			hasBackdrop: true,
			backdropClass: 'backdropBottomSheet',
			panelClass: 'bottomSheetcontainer',
			data: { contacts: this.states }
		});

		agenda.afterDismissed().subscribe(() => {
		});
	}

	protected googleContactsMobile(): void {

		this.auth.loginGoogle().then(social => {
			this.socialUser = social;
			this.auth.getContacts(this.socialUser.authToken).subscribe((res: any) => {

				var contacts = res.connections;
				for (let i = 0; i < res.connections.length; i++) {

					if (contacts[i]?.phoneNumbers?.length > 0 && (contacts[i]?.phoneNumbers?.[0]?.canonicalForm !== undefined || contacts[i].phoneNumbers[0].value !== undefined)) {

						var number = '';
						if(contacts[i]?.phoneNumbers?.[0]?.canonicalForm){

							if(contacts[i].phoneNumbers[0].canonicalForm.startsWith("+34")){
								number = contacts[i].phoneNumbers[0].canonicalForm.substring(3);
							} else {
								number = contacts[i].phoneNumbers[0].canonicalForm;
							}

						} else {
							if(contacts[i].phoneNumbers[0].value.startsWith("+34")){
								number = contacts[i].phoneNumbers[0].substring(3);
							} else {
								number = contacts[i].phoneNumbers[0].value;
							}
						}


						number = number.replace(/\s/g, '');
						var name = contacts[i].names[0].displayName;
						var words = name.split(" ");
						const initials = (words?.[0]?.charAt(0) ?? '') + (words?.[1]?.charAt(0) ?? '');

						var contact: Contact = {
							'name': name,
							'number': number,
							'initials': initials
						};
						this.states.push(contact);
					}
				}
				this.bottomSheetAgenda.open(bottomSheetAgendaComponent, {
					hasBackdrop: true,
					backdropClass: 'backdropBottomSheet',
					panelClass: 'bottomSheetcontainer',
					data: { contacts: this.states }
				});

			})
		}).catch(err => {
			console.log(err);
		});



	}

	protected googleContacts(): void {

		this.auth.loginGoogle().then(social => {
			this.socialUser = social;
			this.auth.getContacts(this.socialUser.authToken).subscribe((res: any) => {

				var contacts = res.connections;
				for (let i = 0; i < res.connections.length; i++) {

					if (contacts[i]?.phoneNumbers?.length > 0 && (contacts[i]?.phoneNumbers?.[0]?.canonicalForm !== undefined || contacts[i].phoneNumbers[0].value !== undefined)) {

						var number = '';
						if(contacts[i]?.phoneNumbers?.[0]?.canonicalForm){

							if(contacts[i].phoneNumbers[0].canonicalForm.startsWith("+34")){
								number = contacts[i].phoneNumbers[0].canonicalForm.substring(3);
							} else {
								number = contacts[i].phoneNumbers[0].canonicalForm;
							}

						} else {
							if(contacts[i].phoneNumbers[0].value.startsWith("+34")){
								number = contacts[i].phoneNumbers[0].substring(3);
							} else {
								number = contacts[i].phoneNumbers[0].value;
							}
						}


						number = number.replace(/\s/g, '');
						var name = contacts[i].names[0].displayName;
						var words = name.split(" ");
						const initials = (words?.[0]?.charAt(0) ?? '') + (words?.[1]?.charAt(0) ?? '');

						var contact: Contact = {
							'name': name,
							'number': number,
							'initials': initials
						};
						this.states.push(contact);
					}
				}
				this.filteredStates = this.stateCtrl.valueChanges
					.pipe(
						startWith(''),
						map(state => state ? this._filterStates(state) : this.states.slice())
					);

			})
		}).catch(err => {
			console.log(err);
		});



	}

	styleBet(): object {
		if (this.options.idGame === EGamesId.EUROMILLONES) {
			// Color of color-euromillones, I can't put it like $color-euromillones
			return { 'border-color': '#ffd94b' };
		}
		if (this.options.idGame == EGamesId.EL_GORDO) {
			return { 'border-color': '#4287f5' };
		}
		return {};
	}

	styleIndexBet(): object {
		if (this.options.idGame === EGamesId.EUROMILLONES) {
			// Color of color-euromillones, I can't put it like $color-euromillones
			return { 'background-color': '#4287f5' };
		}
		if (this.options.idGame === EGamesId.BONOLOTO || this.options.idGame == EGamesId.EL_GORDO) {
			return { 'background-color': '#c92e2e' };
		}
		return {};
	}

	selectContact(contact: Contact){
		this.playFreeService.setContacto(contact);
	}

	setInvitado() {
		this.playFreeService.setInvitadoJugadaGratis(this.invitadoFreePlay);
	}

	ngOnDestroy(): void {
		this.maxNumBets = null
		this.configuracionBeetFree = null
		this.clearBets()
	}

}
