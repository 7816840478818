import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ReponseStandar } from "@models/api-models/response-standar.model";
import { Ong } from "@models/ong.model";
import { ApiRouterService } from "@shared/services/api-router.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import { UserApiService } from "./user.service";
import { Estado, PlaysFilter } from '@models/playsFilter';

@Injectable({
	providedIn: 'root'
})
export class ParticipationService extends ApiRouterService {
	searchTerm: Subject<string> = new Subject()

	private participation: any
	private participation$: BehaviorSubject<any>
	public participationOb: Observable<any>

	constructor(private http: HttpClient, private authService: AuthenticationService) {
		super()

		this.participation$ = new BehaviorSubject<any>(new Array<any>);
		this.participationOb = this.participation$.asObservable();
	}

	set_participation(participation: any) {
		this.participation = participation;
		this.participation$.next(this.participation)
	}

	getProductoParticipacion(id: number, grupo = null) {
		const token = this.authService.loadToken();

		let header = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		let httpOptions = { headers: header };

		if (grupo != null) {
			return this.http.get(`${this.URL}productosPenya/${id}?grupo_id=${grupo}`, httpOptions);
		}

		return this.http.get(`${this.URL}productosPenya/${id}`, httpOptions);
	}

	comprar_participaciones_penya(participations, grupo = null) {
		const token = this.authService.loadToken();

		let header = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		let httpOptions = { headers: header };
		let params = {};
		if (!grupo) {
			params = {
				"productos": participations,
			};
		}
		else {
			params = {
				"productos": participations,
				"grupo_id": grupo
			};
		}
		return this.http.post(`${this.URL}productosPenya/comprar`, params, httpOptions);
	}

	getInfoCampaign(hash: any): Observable<ReponseStandar> {
		let token = this.authService.loadToken()

		let header = new HttpHeaders({
			token
		})

		return this.http.get<ReponseStandar>(`${this.URL}participaciones/campaignInfo/${hash}`, { headers: header })
	}

	/**
	* Gets all content of the plays from the API.
	* @returns Callback from the API.
	*/
	public getParticipations(page = 1, filtros: PlaysFilter): Observable<any> {

		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);

		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		const httpOptions = { headers: this.httpHeaders };
		return this.http.get(`${this.URL}participaciones/listado?page=${page}&juego=${filtros.juego_id}&estado=${filtros.estado}`, httpOptions);

	}

	public getParticipationsProducts(page = 1, filtros: PlaysFilter): Observable<any> {

		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);

		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		const httpOptions = { headers: this.httpHeaders };
		return this.http.get(`${this.URL}productosPenya/listado?page=${page}`, httpOptions);

	}

	public getParticipation(id): Observable<any> {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);

		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		const httpOptions = { headers: this.httpHeaders };
		return this.http.get(`${this.URL}participaciones/${id}`, httpOptions);
	}

	public buyParticipation(data): Observable<any> {
		const token = this.authService.loadToken();

		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		const httpOptions = { headers: this.httpHeaders };
		return this.http.post(`${this.URL}participaciones/compra`, data, httpOptions);
	}

	public subscribeParticipacion(participacion, grupo = null) {
		const token = this.authService.loadToken();

		let header = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		let httpOptions = { headers: header };
		let params = {};
		if (!grupo) {
			params = {
				"participacion": participacion,
			};
		}
		else {
			params = {
				"participacion": participacion,
				"grupo_id": grupo
			};
		}
		return this.http.post(`${this.URL}productosPenya/abono`, params, httpOptions);
	}

	public unsubscribeParticipacion(participacion, grupo = null) {
		const token = this.authService.loadToken();

		let header = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		let httpOptions = { headers: header };
		let params = {};
		if (!grupo) {
			params = {
				"participacion": participacion,
			};
		}
		else {
			params = {
				"participacion": participacion,
				"grupo_id": grupo
			};
		}
		return this.http.post(`${this.URL}productosPenya/abono/cancelar`, params, httpOptions);
	}
}