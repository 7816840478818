<div class="manual-play-bet-wrapper">
	<div class="manual-play-bet-container">

		<swiper-container slides-per-view="1" space-between="0" [navigation]="isSimpleBet" centered-slides="true"
			[cssMode]="!isSimpleBet"><!--[slidesPerView]="1" [spaceBetween]="0" [navigation]="isSimpleBet" [centeredSlides]="true" [cssMode]="!isSimpleBet" TODO ANGULAR16-->
			<swiper-slide *ngFor="let bet of bets; let k = index">
				<div class="bet-element">
					<div class="bet-container" [ngStyle]="styleBetHeight()">
						<div class="element-header">

							<div class="element-action-wrapper">

								<button class="action-automatic" [value]="bet.label" *ngIf="bet.currentAction == 'auto'"
									(click)="createRandomManualBet(bet)">Automático</button>

								<button class="action-automatic" [value]="bet.label"
									*ngIf="bet.currentAction == 'clear'" (click)="clearBet(bet)">Borrar</button>

							</div>

						</div>

						<div class="element-body">

							<div class="element-numbers-wrapper">

								<div class="element-row" *ngFor="let row of bet.mainMatrix; let i = index">

									<div class="element-column" *ngFor="let column of bet.mainMatrix[i]; let j = index">


										<button class="element-number-wrapper Alabel" *ngIf="i == 0 && j == 0">
											A{{ bet.label }}
										</button>

										<button class="element-number-wrapper " [ngStyle]="styleBet()"
											[ngClass]="bet.numbersChecked.includes(bet.mainMatrix[i][j]) ? 'checked' : null"
											[value]="bet.mainMatrix[i][j]" *ngIf="bet.mainMatrix[i][j]"
											(click)="checkCell(bet.mainMatrix[i][j], k, false)">
											{{ bet.mainMatrix[i][j] }}

										</button>
										<button class="element-number-wrapper blank"
											*ngIf="!bet.mainMatrix[i][j] && i != 0 && j != 0"></button>

									</div>

								</div>

							</div>

							<div class="element-stars-wrapper"
								*ngIf="bet.extraMatrix.length > 0 && (options.idGame == 3 || options.idGame == 5 || options.idGame == 2)">



								<div class="element-row" *ngFor="let row of bet.extraMatrix; let i = index">

									<div class="element-column"
										*ngFor="let column of bet.extraMatrix[i]; let j = index">

										<button class="element-star-wrapper"
											[ngClass]="bet.extraChecked.includes(bet.extraMatrix[i][j]) ? 'checked' : null"
											[value]="bet.extraMatrix[i][j]" *ngIf="bet.extraMatrix[i][j] != null"
											(click)="checkCell(bet.extraMatrix[i][j], k, true)" [ngStyle]="styleBet()">

											<span class="number">{{ bet.extraMatrix[i][j] }}</span>

										</button>
										<button class="element-number-wrapper blank"
											*ngIf="!bet.extraMatrix[i][j] && i != 0 && j != 0" ></button>
									</div>

								</div>

							</div>

						</div>
					</div>
				</div>

			</swiper-slide>

		</swiper-container>


		<div class="extra-wrapper" *ngIf="options.idGame == 4">

			<div>
				<span style="align-self: flex-start;">Reintegro</span>

				<div class="container-stars-wrapper">
					<div class="element-row" *ngFor="let row of extraGame; let i = index">
	
						<div class="element-column" *ngFor="let column of extraGame[i]; let j = index">
	
							<button class="element-star-wrapper"
								[ngClass]="extraGameChecked.includes(extraGame[i][j]) ? 'checked' : null"
								[value]="extraGame[i][j]" *ngIf="extraGame[i][j] != null"
								(click)="checkExtraCell(extraGame[i][j])" [ngStyle]="styleBet()">
	
								{{ extraGame[i][j] }}
	
							</button>
							<button class="element-number-wrapper blank" *ngIf="!extraGame[i][j] && extraGame[i][j] == null"></button>
	
						</div>
	
					</div>
				</div>
			</div>

		</div>



	</div>
</div>