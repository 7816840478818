<div  *ngIf="!checkRoute(); then thenBlock else elseBlock"></div>

<ng-template #thenBlock>

  <div class="header-mobile-wrapper" [ngClass]="{'header-mobile-wrapper-light': grupoWhiteMode,'header-mobile-wrapper-cool' : grupoCoolMode}">

    <div class="d-flexbox">
      <div class="btn-accion" (click)="navigate()"  *ngIf="mode=='Ajustes'">
        <img src="/assets/images/icons/header/Settings.svg" alt="img"/>
      </div>

      <div class="btn-accion" (click)="back()"  *ngIf="mode=='BackArrow'">
        <img src="/assets/images/icons/header/arrow-left.svg" alt="img"/>
      </div>

      <div class="title">
        <h4>{{title}}</h4>
      </div>
    </div>

    <div class="scan-container" *ngIf="showBtnRight" (click)="navRightIconClick()">
  
      <img class="scan-icon" src="{{iconRightButton}}" alt="img">
  
    </div>
  
  </div>
  

</ng-template>
<ng-template #elseBlock>

  <!--DETAIL PLAYS-->

</ng-template>
