import { AfterViewChecked, Component, Inject, Renderer2 } from "@angular/core";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { ColorGenerator } from "@classes/ColorGenerator";
import { MPlay } from "@models/api-models/plays-api.models";

@Component({
    selector:'app-bottomSheetParticipantes',
    templateUrl:'./bottomSheetParticipantes.component.html',
    styleUrls:['./bottomSheetParticipantes.component.scss']
})
export class bottomSheetParticipantesComponent implements AfterViewChecked
{
    participantes : any[]=[];
    play: MPlay;
    private colorGenerator: ColorGenerator;
    public totalPorIntgrante: Number = 0;

    constructor(private bottomSheetParticipantes: MatBottomSheetRef<bottomSheetParticipantesComponent>,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data: {participantes: any[], jugada: any},
                private renderer: Renderer2
    ){
        this.play=data.jugada;
        
        //Calculamos el total por integrante a repartir
        if(this.play.simulacion && this.play.simulacion.premio>0)
            this.totalPorIntgrante = this.play.simulacion.premio/data.participantes.length;

        this.participantes.push(...data.participantes);
        this.colorGenerator = new ColorGenerator();
    }

    ngAfterViewChecked()
    {
        this.setRandomColor();
    }

    dimiss()
    {
        this.bottomSheetParticipantes.dismiss();
    }

    getFirstLetter(letra: string)
    {
        return letra.toLocaleUpperCase()[0];
    }

    setRandomColor() 
    {
        document.querySelectorAll("div[class='letter']").forEach(x=>{
            let nombreMiembro = x.parentNode.nextSibling.childNodes[0].textContent;
            this.renderer.setStyle(x,"background-color",this.colorGenerator.strToColor(nombreMiembro));
        })
    }
}