import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { loteriaEmpresaModel } from "@models/api-models/loteria_empresa.models";
import { TokenJWTModel } from "@models/token-JWT.model";
import { ApiRouterService } from "@shared/services/api-router.service";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";

@Injectable({
	providedIn: 'root'
})
export class empresaService extends ApiRouterService {
	private token: TokenJWTModel;
	private httpOptions: any;

	private empresa: loteriaEmpresaModel
	private empresa$: BehaviorSubject<loteriaEmpresaModel>
	public empresaOb: Observable<loteriaEmpresaModel>

	private interfaz_limitada: boolean
	private interfaz_limitada$: BehaviorSubject<boolean>
	public interfaz_limitadaOb: Observable<boolean>

	private accesoEmpresa: number;
	private accesoEmpresa$: BehaviorSubject<number>
	public accesoEmpresaOb: Observable<number>

	constructor(

		private http: HttpClient,
		private helper: JwtHelperService,
		private authService: AuthenticationService,
		private router: Router) {
		super();
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
		});
		this.httpOptions = { headers: this.httpHeaders };

		//Observable empresa
		this.interfaz_limitada = false;
		this.interfaz_limitada$ = new BehaviorSubject(this.interfaz_limitada);
		this.interfaz_limitadaOb = this.interfaz_limitada$.asObservable();

		this.empresa$ = new BehaviorSubject(this.empresa)
		this.empresaOb = this.empresa$.asObservable()

		this.accesoEmpresa$ = new BehaviorSubject(null);
		this.accesoEmpresaOb = this.accesoEmpresa$.asObservable();
	}

	/**
	 * Method that returns the company linked to a pin
	 * @param pin 
	 */
	get_loteria_empresa(pin): Observable<any> {
		return this.http.get(`${this.URL}empresas/${pin}`, this.httpOptions);
	}

	unir_cliente_empresa(id): Observable<any> {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		let body = {}
		const httpOptions = { headers: this.httpHeaders };
		return this.http.post(`${this.URL}empresa/${id}/unirse`, body, httpOptions);
	}

	get_loteria_slug(slug: string): Observable<any> {
		return this.http.get(`${this.URL}empresas/slug/${slug}`, this.httpOptions);
	}

	get_empresa_details(slug: String): Observable<any> {
		return this.http.get(`${this.URL}empresas/details/${slug}`, this.httpOptions);
	}

	set_empresa(empresa: loteriaEmpresaModel) {
		this.empresa = empresa;
		this.empresa$.next(empresa)
		this.interfaz_limitada = this.empresa.interfaz_limitada;
		this.interfaz_limitada$.next(this.interfaz_limitada);
	}

	set_interfaz(active: boolean) {
		this.interfaz_limitada = active;
		this.interfaz_limitada$.next(this.interfaz_limitada);
	}

	getEmpresasAsociadasCliente() {
		const httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'token': this.authService.loadToken(),
		});
		return this.http.get(`${this.URL}empresasAsociadas`, { headers: httpHeaders });
	}

	getDetallesEmpresaAsociada(id: Number) {
		const httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'token': this.authService.loadToken(),
		});

		return this.http.get(`${this.URL}empresasAsociadas/${id}`, { headers: httpHeaders });
	}

	getResumenEmpresaAsociada(id: Number) {
		const httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'token': this.authService.loadToken(),
		});

		return this.http.get(`${this.URL}empresasAsociadas/${id}/resumen`, { headers: httpHeaders });
	}

	getSorteosLoteriaEmpresa(type: string = "empresas") {
		const httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'token': this.authService.loadToken(),
		});

		return this.http.get(`${this.URL}empresas/sorteos?type=${type}`, { headers: httpHeaders })
	}

	createEmpresa(data: any) {
		const httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'token': this.authService.loadToken(),
		});

		return this.http.post(`${this.URL}empresas/create`, data, { headers: httpHeaders })
	}

	updateEmpresa(data: any, empresaId: Number) {
		const httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'token': this.authService.loadToken(),
		});

		return this.http.put(`${this.URL}empresas/${empresaId}/editar`, data, { headers: httpHeaders })
	}



	getEmpresasListado(page: Number) {
		const httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'token': this.authService.loadToken(),
		});

		return this.http.get(`${this.URL}empresas/listado?page=${page}`, { headers: httpHeaders })
	}

	getDatosPanel(data: any, clienteId: Number) {
		const httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'token': this.authService.loadToken(),
		});

		let params = {
			'id': data
		}

		return this.http.post(`${this.URL}empresas/${clienteId}/panel`, params, { headers: httpHeaders })
	}

	getAccessControl(data: string, id: number): Observable<any> {
		const httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'token': this.authService.loadToken(),
		});

		let params = {
			'ac_data_values': data
		}

		return this.http.post(`${this.URL}empresas/accesscontrol/${id}`, params, { headers: httpHeaders })
	}

	setAcceso(id: number) {
		this.accesoEmpresa = id;
		this.accesoEmpresa$.next(this.accesoEmpresa);
	}
}