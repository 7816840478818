import { Component, HostListener } from '@angular/core';
import { FireBase } from '@classes/fireBase';
import { EDevice, EDeviceSize } from '@enums/common/sizes.enum';
import { ApplicationStateService } from '@shared/services/application-state.service';

@Component({ template: '' })
export abstract class CCoreComponent extends FireBase {

  public device: EDevice;
  public devices: typeof EDevice;
  public applicationStateService: ApplicationStateService;

  constructor(

    applicationStateService: ApplicationStateService,

  ) {
    super()
    this.applicationStateService = applicationStateService;
    this.device = this.getInitialDevice();
    this.devices = EDevice;

  }

  /**
   * Method to capture the document width at the initialization moment.
   * @return EDevice.
   */
  private getInitialDevice(): EDevice {

    const width = document.documentElement.clientWidth;
    return width < EDeviceSize.MAXMOBILE ? EDevice.MOBILE : EDevice.DESKTOP;

  }

  /**
   * Method to define the device on use on window's resize.
   * @param window the window.
   */
  @HostListener('window:resize', ['$event.target'])
  public onResize(window: Window): void {

    window.addEventListener('deviceorientation',(ev)=>{
      ev.preventDefault()
    })

    if (this.device === EDevice.DESKTOP && window.innerWidth < EDeviceSize.MINDESKTOP) {

      this.applicationStateService.setApplicationDevice(EDevice.MOBILE);

    } else if (this.device === EDevice.MOBILE && window.innerWidth > EDeviceSize.MAXMOBILE) {

      this.applicationStateService.setApplicationDevice(EDevice.DESKTOP);

    }

  }

}
