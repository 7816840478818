import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MPlay } from '@models/api-models/plays-api.models';
import { MatDialog } from '@angular/material/dialog';
import { PlaysAPIService } from '@api/services/games/plays-api.service';
import { bottomSheetRegalarComponent } from '@shared/components/bottomSheet/bottomSheetRegalar/bottomSheetRegalar.component';
import { bottomSheetAbonoComponent } from '@shared/components/bottomSheet/bottomSheetAbono/bottomSheetAbono.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ToastrService } from "ngx-toastr";
import { FireBase } from '@classes/fireBase';
import { FiltrosService } from '@api/services/filtros.service';
import { BottomSheetRepetirJugadaComponent } from '@shared/components/bottomSheet/bottomSheetRepetirJugada/bottomSheetRepetirJugada.component';
import { modalAlertaComponent } from '@shared/components/modals/modalAlerta/modalAlerta.component';
import { BetService } from '@modules/games/bet.service';
import { UserApiService } from '@api/services/user.service';
import { ConfigurationApiService } from '@api/services/configuration.service';
import { ParticipationService } from '@api/services/participation.service';
import { modalYesNoComponent } from '@shared/components/modals/modalYesNo/modalYesNo.component';
@Component({
	selector: 'app-participation-product',
	templateUrl: './participation-product.component.html',
	styleUrls: [
		'./participation-product.component.scss',
	]
})
export class ParticipationProductoComponent  extends FireBase implements OnInit {

	selected = false;

	@Input() play: any;
	@Input() last;
	@Input() jugada: any;
	@Input() device: any;
	@Input() abono: boolean = false
	@Output() dropAbono: EventEmitter<MPlay> = new EventEmitter()

	public precioGuindaFinal: number;
	public saldo: number;
	public guindaConfig: boolean = false;
	private route: string;

	public imagen: boolean;

	public grupo = null;

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private playsApiService: PlaysAPIService,
		private bottomSheetRegalar: MatBottomSheet,
		private bottomSheetAbono: MatBottomSheet,
		private toastrService: ToastrService,
		private filterService: FiltrosService,
		private sheet: MatBottomSheet,
		private betService: BetService,
		private userService: UserApiService,
		private configService: ConfigurationApiService,
		private participationService: ParticipationService
	) {
		super();

		this.userService.usuarioObservable.subscribe( x => {
			if(x)
				this.saldo = x.client.getSaldoTotal();
		})
		this.configService.observableGuinda.subscribe(x => {
			this.guindaConfig = x;
		});
	}

	
	ngOnInit(): void {
		const url = this.router.url.split('/')
		if (url.includes('mis-grupos')) {
			this.route = '/cliente/mis-grupos/detalles/' + url[4] + '/' + url[5] + '/participacion';
			this.grupo = url[4];
		}
		else {
			this.route = '/cliente/participaciones';
		}

		this.imagen = this.play.producto.penya.imagen == null;
	}
	
	openOptions() {
		this.selected = !this.selected;
	}
	
	public detailPlay(play: MPlay): void {

		if (this.play.producto && this.play.jugadas.length == 0)
			this.infoParticipacion()
		else {
			this.participationService.set_participation(this.play);
			this.router.navigate([this.route, play.id]);
		}

	}

	public desabono() {
		this.participationService.unsubscribeParticipacion(this.play.id, this.grupo).subscribe(res => {
			this.play.abono = null;
			this.toastrService.success("Abono cancelado", "", {
				positionClass: 'toast-bottom-center',
			});
		});
	}

	public repeat() {
		this.sheet.open(BottomSheetRepetirJugadaComponent, { data: { play: this.play, modoGrupo: false } })
	}

	openAbono() {
		const texto = 'Jugarás una participación con el mismo importe cada vez que juegue la peña. Mucha suerte';
		const alerta = this.dialog.open(modalYesNoComponent, {
			panelClass: 'modal-custom',
			data: {
				title: '¡Atencion vas a abonarte!',
				texto,
				buttonConfirm: 'Abonarme',
				btnConfirmColor: 'blue',
				buttonCancel: 'Cancelar',
				showIcon: false,
				showOptionCheck: true
			}
		});

		alerta.afterClosed().subscribe(res => {
			if (res) {
				this.participationService.subscribeParticipacion(this.play.id, this.grupo).subscribe(res => {
					this.play.abono = true;
				});
				this.toastrService.success("Participación abonada", "", {
					positionClass: 'toast-bottom-center',
				});
			}
		});
	}

	public infoParticipacion(): void {
		this.dialog.closeAll();
		let dialogAlert = this.dialog.open(modalAlertaComponent, {
			panelClass: 'modal-custom',
			data: {
				title: 'Aviso',
				texto: 'Recuerda que las jugadas estarán disponibles cuando se cierre la peña a las 20:00',
				buttonConfirm: 'Continuar',
				buttonCancel: 'Continuar',
				buttonConfirmColor: "blue",
				showIcon: false,
				showOptionCheck: false
			}
		});
	}
}
