import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'advice-bet',
  templateUrl: 'advice-bet.component.html'
})
export class AdviceBetComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any){
    
  }
}