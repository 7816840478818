<div class="played-lottery-wrapper">

	<div class="numbers-overview" *ngIf="numbersShow.length >= 1 && !manual && !automatic">
		<span class="text">Número de décimos</span>
		<div class="label"><span>{{this.numbers.length}}</span></div>
	</div>

	<div class="single-lottery" *ngIf="numbersShow.length === 1 && !manual && automatic">
		<div class="ticket" [ngStyle]="{'background-image': backgroundImage}" >
			<span class="numbers" [ngClass]="{'empty': image == ''}">{{numbersShow[0].number}}</span>
		</div>

		<button class="change-button" (click)="triggerChangeNumber()">Cambiar <img class="icon"
				src="../../../../../../assets/images/icons/play-detail/shuffle.svg">
		</button>

	</div>

	<div class="numbers-list" *ngIf="!manual && !automatic && numbersShow.length > 0">
		<div *ngFor="let number of numbersShow" class="number-item">
			<img [src]="imageMult ? imageMult : './../../../../../assets/images/background/empty_ticket.svg'" class="number-img">
			<div class="number-label">
				<span class="number">Nº {{number.number}}</span>
				<span class="amount">x{{number.count}}</span>
			</div>
			<button class="delete-button" (click)="deleteNumber(number.number)"><img src="/assets/images/icons/grupos/miembros/delete.svg" alt="img"></button>
		</div>
	</div>

	<div class="multiple-lottery" *ngIf="manual && numbersShow.length > 0">
		<div class="selected-count" (click)="toggleTicketsVisibility()">{{numbers.length}} décimos seleccionados
			<button class="visible-button">
				<img class="icon" src="../../../../../../assets/images/icons/down.svg" [ngClass]="{'rotate': ticketsVisible}">
			</button>
		</div>
		<div class="selected-tickets" [ngClass]="{'hidden': !ticketsVisible}">
			<div class="selected-ticket" *ngFor="let number of numbersShow" [ngStyle]="{'background-image': backgroundImage}">
				<span class="ticket-number" [ngClass]="{'ticket-empty': imageMult == ''}">{{number.number}}</span>
				<div class="counter-badge">{{number.count}}</div>
				<button class="delete-button" (click)="deleteNumber(number.number)">X</button>
			</div>
		</div>
	</div>

</div>