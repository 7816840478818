import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { HeaderService } from "@api/services/header.service";
import { take } from "rxjs/operators";

@Component({
    selector:'app-puntos-venta-desktop',
    templateUrl:'./puntos-venta-desktop.component.html',
    styleUrls:['./puntos-venta-desktop.component.scss']
})
export class puntosVentaDesktopComponent
{
    public numeroSearch: string;
    public load = false;

    modeGroup = false

    constructor(private routerActive: ActivatedRoute,
                private route: Router,
                private headerService: HeaderService
        ){
        this.loadParams();
    }

    loadParams()
    {
        this.routerActive.queryParams.subscribe(x=>{
            this.numeroSearch=x.num;
            if(x.grupo_id)
                this.modeGroup = true
        });
    }

    back()
    {
        this.headerService.rutaBackOb
        .pipe(take(1))
        .subscribe(res => {
            this.route.navigate([res])
        })
    }
}