import { AfterContentChecked, Component, ElementRef, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, NgZone, AfterViewInit } from '@angular/core';
import { ReservasService } from "@api/services/reservas.service";
import { MReservas } from "@models/reservas.model";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { CdkVirtualScrollViewport, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { CustomVirtualScroll } from '@classes/CustomVritualScoll';
import { take, debounceTime, filter, map, pairwise, throttleTime } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { PlaysAPIService } from '@api/services/games/plays-api.service';
import { PlaysService } from '@api/services/play.service';
import { MPlay } from '@models/api-models/plays-api.models';
import { ToastrService } from 'ngx-toastr';
import { ScannedPlayService } from '@api/services/scannedPlay.service';

@Component({
	selector: 'app-bottom-sheet-nombre-jugada',
	templateUrl: 'bottomSheetNombreJugada.component.html',
	styleUrls: ['bottomSheetNombreJugada.component.scss']
})
export class bottomSheetNombreJugada implements OnInit {

	public play: MPlay;
	public nombreInicial: string;
	public nombre: string;

	constructor(private playsAPIService: PlaysAPIService,
		private bottomSheetNombreJugada: MatBottomSheetRef<bottomSheetNombreJugada>,
		@Inject(MAT_BOTTOM_SHEET_DATA) public data: { id: any, tipo: string },
		private toastrService: ToastrService, private scannedPlayService: ScannedPlayService) {
	}

	ngOnInit() {
		this.playsAPIService.selectJugadaOb.subscribe(x => { this.play = x; });
		this.nombreInicial = this.play.nombre != null ? this.play.nombre : null;
		this.nombre = this.nombreInicial;
	}

	confirmar() {
		this.play.nombre = this.nombre;
		if (this.data.tipo == "Jugada") {
			this.playsAPIService.setNombreJugada(this.play).pipe(take(1)).subscribe((res: any) => {
				if (res.status == 200) {
					this.dismiss();
				}
				else {
					this.play.nombre = this.nombreInicial;
					this.toastrService.error('Error al cambiar el nombre', "Error", {
						positionClass: 'toast-bottom-right'
					});
				}
			});
		}
		else {
			this.scannedPlayService.setNombreJugada(this.play).pipe(take(1)).subscribe((res: any) => {
				if (res.status == 200) {
					this.dismiss();
				}
				else {
					this.play.nombre = this.nombreInicial;
					this.toastrService.error('Error al cambiar el nombre', "Error", {
						positionClass: 'toast-bottom-right'
					});
				}
			})
		}

	}

	cancelar() {
		this.dismiss();
	}

	dismiss() {
		this.bottomSheetNombreJugada.dismiss();
	}
}
