import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HeaderService } from "@api/services/header.service";

@Component({
    selector:'app-modal-alerta',
    templateUrl:'./modalAlerta.component.html',
    styleUrls:['./modalAlerta.component.scss']
})
export class modalAlertaComponent
{
    public modeGroup: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string,texto: string, buttonConfirm: string, showOptionCheck: boolean},
                headerService: HeaderService
    ){
        headerService.modeGrupoCoolOb.subscribe(res=>this.modeGroup=res);
    }
}