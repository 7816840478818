<div class="toast-wrapper">

    <div *ngIf="title || message" class="toast-container-show">

        <div class="toast-title">
            <span>{{title}}</span>
        </div>

        <div class="toast-message">
            <span>{{message}}</span>
        </div>

    </div>
    <div *ngIf="!title && !message && (lastTitle || lastMessage)" class="toast-container-hide">

        <div class="toast-title">
            <span>{{lastTitle}}</span>
        </div>

        <div class="toast-message">
            <span>{{lastMessage}}</span>
        </div>

    </div>
    
</div>