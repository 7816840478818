export enum Propiedad
{
    individual = "individual",
    compartido = "compartido",
    regalada = "regalada",
    todos = "todos"
}

export enum Estado
{
    activos = "activos",
    sorteados = "sorteados",
    abonos = "abonos",
    premiados = "premiados",
    todos = "todos"
}

export class PlaysFilter
{
    public propiedad: Propiedad;
    public estado: Estado;
    public juego_id: number;

    constructor(){
        this.estado = Estado.todos
        this.propiedad = Propiedad.todos
        this.juego_id = -1;
    }
}