<div class="modal">

	<div class="title">Acceso a Empresa</div>
	<div class="subtitle">Tu empresa/organización requiere que introduzcas los siguientes datos para proceder con la compra.</div>

	<div mat-dialog-content>
		<form [formGroup]="dynamicForm">
			<div *ngFor="let label of labels; let i = index" style="margin-bottom: 16px;">
				<div>
					<strong>{{ label }}</strong>
				</div>
				<mat-form-field appearance="fill" style="width: 100%;">
					<input matInput [formControlName]="'param' + i" [type]="label.toLowerCase().includes('fecha') ? 'date' : 'text'" [placeholder]="label.toLowerCase().includes('fecha') ? 'Ej. 19/03/1969' : label">
				</mat-form-field>
			</div>
		</form>
	</div>

	<div mat-dialog-actions class="footer">
		<button mat-button class="cancel" (click)="onCancel()">Cancelar</button>
		<button mat-button class="submit" (click)="onSubmit()" [disabled]="dynamicForm.invalid">Enviar</button>
	</div>
</div>