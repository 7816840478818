import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Mygroup } from "@models/group.model";
import { Estado, PlaysFilter } from "@models/playsFilter";
import { ApiRouterService } from "@shared/services/api-router.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import { shareReplay } from "rxjs/operators";

@Injectable({
    providedIn:'root'
})
export class GroupService extends ApiRouterService
{
    private grupo: Mygroup;

    private grupo$: BehaviorSubject<Mygroup>;
    public getGroup: Observable<Mygroup>;

    private mvGrupo: Subject<any>;
    public getMvGrupo: Observable<any>;

    public playsOb$: Observable<any>

    constructor(
        private http : HttpClient,
        private authService: AuthenticationService
    ){
        super();
        this.grupo$=new BehaviorSubject<Mygroup>(this.grupo);
        this.getGroup= this.grupo$.asObservable();

        this.mvGrupo= new Subject<any>();
        this.getMvGrupo=this.mvGrupo.asObservable();
    }
    
    /**
     * Funcion para obtener la lista de grupos
     * @returns Observable<Object>
     */
    listaGrupos(): Observable<Object>
    {
        const token = this.authService.loadToken();

        let cabecera = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });

        return this.http.get(`${this.URL}penyas`,{headers:cabecera});
    }

	public setSaldo(miembro, saldo: number): void 
	{
		var p = this.grupo;

		let persona = p.miembros.findIndex((x:any)=> x.cliente_id == miembro);

		p.miembros[persona].saldo.saldo_tarjeta = saldo;

		this.grupo = p;

		this.grupo$.next(this.grupo);
	}

	public setSaldoGrupo(saldo: number): void {
		var p = this.grupo;
		p.saldo.saldo_tarjeta = saldo;
		this.grupo = p;
		this.grupo$.next(this.grupo);
	}

    /**
     * Hace la llamda a un grupo
     */
    get_grupo(idGrpo)
    {
        const token = this.authService.loadToken();

        let cabecera = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });

        return this.http.get(`${this.URL}penyas/${idGrpo}/info`,{headers:cabecera});
    }

    /**
     * Carga el grupo que tu elijas en el obserbable
     * @param group
     */
    set_group(group: Mygroup)
    {
        this.grupo=group;
        this.grupo$.next(group);
    }

    /**
     * Funciona para crear un grupo
     */
    crearGrupo(grupo: Mygroup, file: File)
    {
        const token = this.authService.loadToken();

		if (!token) {
			return null;
		}

		const formData: FormData = new FormData();
		formData.append('nombre', grupo.nombre);
    	formData.append('reparto_premios', grupo.reparto_premios);
    	formData.append('modo_participacion', grupo.modo_participacion);

		if (file != null) {
			formData.append('imagen', file);
		}

		let cabecera = new HttpHeaders({
			token
		});

        return this.http.post(`${this.URL}penyas/crear`, formData, {headers:cabecera});
    }

    /**
     * Metodo actualizar grupo
     */
    updateGrupo(grupo: Mygroup, file: File): Observable<any>
    {
        const token = this.authService.loadToken();
		if (!token) {
			return null;
		}

		const formData: FormData = new FormData();
		formData.append('nombre', grupo.nombre);
    	formData.append('reparto_premios', grupo.reparto_premios);
    	formData.append('modo_participacion', grupo.modo_participacion);

		if (file != null) {
			formData.append('imagen', file);
		}

		let cabecera = new HttpHeaders({
			token
		});

        return this.http.post(`${this.URL}penyas/${grupo.cliente_id}/configuracion?_method=PUT`, formData, {headers:cabecera});
    }

    /**
     * Metodo para abandonar el grupo
     */
    abaondonarGrupo(grupo: any)
    {
        const token = this.authService.loadToken();

        let cabecera = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });

        return this.http.post(`${this.URL}penyas/abandonarPenya`,JSON.stringify(grupo),{headers:cabecera});
    }

    /**
     * Get code for invite user in the group
     * @param grupo 
     * @returns Observable
     */
    invitarParticipante(grupo: Mygroup)
    {
        const token = this.authService.loadToken();

        let cabecera = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });

        return this.http.get(`${this.URL}penyas/${grupo.cliente_id}/invitar-por-codigo`,{headers:cabecera});
    }

    /**
     * Set user to group
     * @param codigo
     * @returns Observable
     */
    ingresarPenya(codigo)
    {
        const token = this.authService.loadToken();

        let cabecera = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });

        return this.http.put(`${this.URL}penyas/entrar-por-codigo/${codigo}`,{},{headers:cabecera});
    }

    setMovimientosGrupo(movimientos: any)
    {
        this.mvGrupo.next(movimientos);
    }

    obtenerMovimientos(grupo: Mygroup)
    {
        const token = this.authService.loadToken();

        let cabecera = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        return this.http.get(`${this.URL}penyas/saldo/${grupo.cliente_id}`,{headers:cabecera});
    }

	getGruposMiembro(){
        const token = this.authService.loadToken();
        let cabecera = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        return this.http.get(`${this.URL}personas/grupos`,{headers:cabecera});
    }

    getPlaysApi(grupo: Mygroup, page=1, filtros: PlaysFilter, abonos = false)
    {
        const token = this.authService.loadToken();

        let cabecera = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        if(!abonos)
            this.playsOb$ = this.http.get(`${this.URL}penyas/${grupo.cliente_id}/jugadas?page=${page}&estado=${filtros.estado}&juego=${filtros.juego_id}`,{headers:cabecera}).pipe(shareReplay());
        else
            this.playsOb$ = this.http.get(`${this.URL}penyas/${grupo.cliente_id}/jugadas?page=${page}&estado=${Estado.abonos}`,{headers:cabecera}).pipe(shareReplay());
    }

    getParticipantesJugada(jugda_id,penya_id)
    {
        const token = this.authService.loadToken();

        let cabecera = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        return this.http.get<any[]>(`${this.URL}penyas/${penya_id}/${jugda_id}`,{headers:cabecera});
    }

    ingresarSaldoGrupo(grupo: Mygroup,cantidad: number)
    {
        const token = this.authService.loadToken();

        let cabecera = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });

        let params = {
            penya_id:grupo.cliente_id,
            cantidad 
        }
        return this.http.post(`${this.URL}penyas/ingreso`,JSON.stringify(params),{headers:cabecera});
    }

	ingresarSaldoMiembroGrupo(grupo: Mygroup, cantidad: number, miembro)
    {
        const token = this.authService.loadToken();

        let cabecera = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });

        let params = {
            penya_id:grupo.cliente_id,
            cantidad ,
			amigo:miembro
        }
        return this.http.post(`${this.URL}penyas/ingreso-amigo`,JSON.stringify(params),{headers:cabecera});
    }

    retirarSaldoGrupo(grupo: Mygroup,cantidad: number)
    {
        const token = this.authService.loadToken();

        let cabecera = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        
        return this.http.get(`${this.URL}penyas/retirar-dinero/${grupo.cliente_id}?cantidad=${cantidad}`,{headers:cabecera});
    }

    deleteMiembro(grupo: Mygroup, miembro_id: number)
    {
        const token = this.authService.loadToken();

        let cabecera = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });

        let params = {
            penya_id:grupo.cliente_id,
            usuario_id:miembro_id
        }

        return this.http.post(`${this.URL}penyas/eliminarUsuario`,JSON.stringify(params),{headers:cabecera});
    }

    abonoPlay(idJugada, cliente_id = -1): Observable<any>{
        const token = this.authService.loadToken();
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        const httpOptions = { headers: this.httpHeaders };
        return this.http.post(`${this.URL}abonos`, {jugada_id: idJugada, grupo_cliente_id: cliente_id}, httpOptions );
    }

    desabonoPlay(idJugada, cliente_id=-1): Observable<any>{
        const token = this.authService.loadToken();
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        const httpOptions = { headers: this.httpHeaders };
        return this.http.delete(`${this.URL}abonos/${idJugada}?grupo_cliente_id=${cliente_id}`, httpOptions );
    }

    hacerAdmin(grupo_id,id_nuevoAdmin)
    {

        const token = this.authService.loadToken();

        let cabecera = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });

        let params = {
            id_nuevoAdmin
        }

        return this.http.put(`${this.URL}penyas/${grupo_id}/cambiarAdmin`,JSON.stringify(params),{headers:cabecera});
    }

	setMiembroAviso(grupo_id, aviso, limite, chat) {
		const token = this.authService.loadToken();

        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
		const httpOptions = { headers: this.httpHeaders };

        let params = {
            'grupo_id': grupo_id,
			'aviso': aviso,
			'limite': limite,
			'chat': chat
        };

        return this.http.post(`${this.URL}penyas/configuracion-aviso-saldo`,JSON.stringify(params),httpOptions);
	}

	borrarImagen(id: number): Observable<any> {
		let token = this.authService.loadToken();
		if (!token) {
			return null;
		}

		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };

		return this.http.delete(`${this.URL}penyas/${id}/imagen`, httpOptions);
	}

	sendChatNotification(grupo_id) {
		let token = this.authService.loadToken();
		if (!token) {
			return null;
		}

		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };

		return this.http.get(`${this.URL}penyas/${grupo_id}/chat`, httpOptions);
	}
}