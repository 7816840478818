<div class="bottomSheetContainer">
	<div class="button" (click)="dismiss()">
		<div class="btn-ln">
			<div class="line" [ngClass]="{'line-cool': false}">

			</div>
			<h4>{{titulo}}</h4>
		</div>
	</div>
	<div class="container">
		<div class="card">
			<div class="body1">
				<div class="titulo">
					<h4>Elige el número de tu décimo</h4>
				</div>
				<div class="header-list card">
					<div class="numero-loteria-search" [formGroup]="numberForm">
						<input #search type="text" maxlength="5" formControlName="num" (input)="newSearchNumber()"
							placeholder="Numeros disponibles">
						<img src="/assets/images/icons/header/Search.svg" (click)="newSearchNumber()" />
					</div>
				</div>
				<div class="number-selection">
					<cdk-virtual-scroll-viewport #scroll itemSize="15" class="scroll">
						<div class="numbers-list">
							<div class="number-detail" [ngClass]="{'mb-1': false}"
								*ngFor="let line of linesToWrite; let last = last" [id]="line.numero">
								<div class="boleto-info">
									<div class="number-image">
										<img class="image" [src]="url" alt="img">
									</div>
									<div class="number-data">
										<h3>Nº {{line.numero}}</h3>
										<p>Quedan {{line.cantidad}}</p>
									</div>
								</div>
								<div class="number-layout">
									<mat-radio-group name="numeroEleccion">
										<mat-radio-button class="radio-button" [value]="line.numero"
											(click)="selectRadio($event)"></mat-radio-button>
									</mat-radio-group>
								</div>
							</div>
							<div class="error" *ngIf="empty">
								<h3 class="no-plays-text">
									Aquí parece que todavía no hay mucho que ver
								</h3>
								<img class="image" src="/assets/images/icons/plays-list/no-plays.svg" />
							</div>
							<div class="error" *ngIf="error || errorLim">
								<h3 *ngIf="error">Se debe seleccionar la cantidad y el sorteo para poder elegir un
									número</h3>
								<h3 *ngIf="errorLim">La cantidad a reservar está por encima de lo permitido</h3>
							</div>
							<div class="bottom-spinner" *ngIf="!error && !errorLim">
								<mat-progress-spinner class="spinner" *ngIf="spinnerBottomLoadVisibility"
									[mode]="'indeterminate'" [diameter]="35"> </mat-progress-spinner>
							</div>
						</div>
					</cdk-virtual-scroll-viewport>
				</div>
			</div>
		</div>

		<div class="bottom-butons">
			<button class="btn-cancel" (click)="cancelar()">Cancelar</button>
			<button class="btn-filtrar" (click)="confirmar()">Confirmar</button>
		</div>

	</div>
</div>