import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
	apiKey: "AIzaSyCRxkKG1kEEKxI5dgcM1w8nbSWIESbf8lU",
	authDomain: "miloto---app.firebaseapp.com",
	databaseURL: "https://miloto---app-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "miloto---app",
	storageBucket: "miloto---app.appspot.com",
	messagingSenderId: "598742413038",
	appId: "1:598742413038:web:7e984f6f249e8cb1a98dff",
	measurementId: "G-TXWBNTMQJN"
};

export class FireBase
{
    protected app = initializeApp(firebaseConfig);
    protected analytics = getAnalytics(this.app);
	protected database = getDatabase(this.app);
}