export class MClient {

  constructor(
    public id: number,
    public balanceCc: string,
    public balancePrizes: string,
  ) {}

  public getSaldoTotal(): number {
    return Number((parseFloat(this.balanceCc) + parseFloat(this.balancePrizes)).toFixed(2))
  }

}
