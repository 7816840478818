<div class="preloader-full-size" *ngIf="forceTo == 'full-sized'">

  <div class="spinner-static">
    <img src='../../../../assets/images/logo.png'>
  </div>

  <div class="loader">
    <span></span>
  </div>

</div>
<div class="preloader-component" *ngIf="forceTo == 'component-sized'">

  <div class="spinner-static">
    <img src='../../../../assets/images/logo.png'>
  </div>
  
  <div class="loader">
    <span></span>
  </div>

</div>