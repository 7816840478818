import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { AuthenticationModule } from './layouts/authentication/authentication.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';

// IMPORT FUNCTION TO REGISTER SWIPER CUSTOM ELEMENTS
import { register } from 'swiper/element/bundle';
// REGISTER SWIPER CUSTOM ELEMENTS
register();

import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from '@abacritt/angularx-social-login';
import { ESiteUrl } from './enums/common/site.enum';
import { environment as environmentProduction } from 'src/environments/environment.prod';
import { environment as environmentDevelop } from 'src/environments/environment';
import { environment as environmentLocal } from 'src/environments/environment.local';
import { IEnvironment } from 'src/environments/environment.interface';
import { GlobalModule } from '@shared/modules/global.module';
import {
	RecaptchaModule,
	RECAPTCHA_SETTINGS,
	RecaptchaSettings,
	RecaptchaFormsModule,
	RECAPTCHA_V3_SITE_KEY,
	RecaptchaV3Module
} from 'ng-recaptcha';

import localEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { HttpInterceptCodes } from '@api/services/interceptor.service';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from '@classes/core/CustomRouteReuseStrategy';
import { AzureLoginModule } from './layouts/authentication/azure-login/azurelogin.module';
registerLocaleData(localEs);

export function tokenGetter(): string | null {
	return localStorage.getItem('access_token');
}

const googleLoginOptions = {
	scope: 'profile email https://www.googleapis.com/auth/contacts.readonly' 
};

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		AppRoutingModule,
		AuthenticationModule,
		BrowserModule,
		BrowserAnimationsModule,
		FontAwesomeModule,
		GlobalModule,
		HttpClientModule,
		SocialLoginModule,
		RecaptchaModule,
		RecaptchaFormsModule,
		RecaptchaV3Module,
		ToastrModule.forRoot(),
		JwtModule.forRoot({
			config: {
				tokenGetter,
				allowedDomains: ['localhost:4200', 'localhost:8000'],
				disallowedRoutes: []
			}
		}),
		AzureLoginModule
	],
	providers: [
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: false,
				providers: [
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(AppModule.getCurrentEnvironment().configurations.GOOGLECLIENTID) // TODO GOOGLE OPTIONS googleLoginOptions
					},
					{
						id: FacebookLoginProvider.PROVIDER_ID,
						provider: new FacebookLoginProvider(AppModule.getCurrentEnvironment().configurations.FACEBOOKCLIENTID)
					}
				],
				onError: (err) => {
				  console.error(err);
				}
			} as SocialAuthServiceConfig,
		},
		/*{ Volver a poner para cuando quereamos hacer que los componentes no se recreen constantemente
			provide: RouteReuseStrategy,
			useClass: CustomRouteReuseStrategy
		},*/
		{
			provide: LOCALE_ID,
			useValue: 'es'
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpInterceptCodes,
			multi: true
		},
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: AppModule.getCurrentEnvironment().configurations.recaptcha
		}

	],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA,
	],
	bootstrap: [AppComponent]

})
export class AppModule {

	constructor() { }

	private static getCurrentEnvironment(): IEnvironment {

		const currentBaseUrl = window.location.origin;
		let result: IEnvironment = environmentDevelop;

		switch (currentBaseUrl) {
			case ESiteUrl.PRODUCTION:
				result = environmentProduction;
				break;
			case ESiteUrl.DEVELOP:
			case ESiteUrl.LOCAL:
				result = environmentLocal;
				break;
		}

		return result;

	}

}
