// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { truncate } from 'fs';
import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
	production: true,
	local: false,
	intranet: false,
	configurations: {
		GOOGLECLIENTID: '607964059072-o7jbkq6b0t1mp1jubdvn9viocj0m9hdu.apps.googleusercontent.com',
		FACEBOOKCLIENTID: '1062688923834041',
		recaptcha: "6LeXIlUmAAAAAL8w_ncFRDnFUb5x0JXsyR8EC1X9",
	},
	digitaloceanspaces: {
		key: "DO00N6H3HHWZCKE8GVJM",
		access_key: "wxrTHAn3vl4UA0B3Fh1MVyY61WliCl8Wwn3hGLJ84kY"
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.