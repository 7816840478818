import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MPermisoRecogida } from "@models/permiso-recogida.model";
import { ApiRouterService } from "@shared/services/api-router.service";
import { Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";

@Injectable({
    providedIn:'root'
})
export class PedidosService extends ApiRouterService
{
    constructor(
        private http: HttpClient,
        private authService: AuthenticationService
    ){
        super();
    }

    /**
     * Metodo para añadir el permirso a una perosna para recoger una persona
     */
    addPersonRecogerPedido(personaPermititda: MPermisoRecogida)
    {
        const token = this.authService.loadToken();

        let header = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });

        const httpOptions = {headers: header};

        return this.http.post(`${this.URL}permisoRecogidaPedido/addPersona`,JSON.stringify(personaPermititda),httpOptions);
    }

    /**
     * Metodo para obtener al usuario autorizado a la recogida de un pedido
     * @param idPedido - El id de un pedido
     */
    getPersonaRecogida(idPedido)
    {
        const token = this.authService.loadToken();

        let header = new HttpHeaders({
            'Content-Type':'application/json',
            token
        });

        const httpOptions = {headers: header};

        return this.http.get(`${this.URL}permisoRecogidaPedido/getPersonaRe/${idPedido}`,httpOptions);
    }   

    getSeguimientoPedido(idPedido, idJugada) : Observable<any> {
        const token = this.authService.loadToken();

        let header = new HttpHeaders({
            'Content-Type':'application/json',
            token
        });

        const httpOptions = {headers: header};

        return this.http.get(`${this.URL}pedidos/${idPedido}/seguimiento?jugada_id=${idJugada}`,httpOptions);
    }
}