export class MReservas
{
    public reserva_id: number;
    public numero_sorteo: number;
    public numero: string;
    public nombre_reserva: string;
    public nombreJuego: string;
    public juego_id: number;
    public fecha: string;
    public email: string;
    public cantidad_reservada: number;
    public cantidad_comprada: number;
    public cantidad: number;
    public envio_id: number;
    public precio: number;
    public link_compartir: string;
}

export class MMiembroReserva
{
    public id: number;
    public nombre: string;
    public apellido_1: string;
    public apellido_2: string;
    public email: string;
    public tipo: string;
}