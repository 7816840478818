import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
	selector:'app-modalInput',
	templateUrl:'./modalInput.component.html',
	styleUrls:['./modalInput.component.scss']
})
export class modalInputComponent
{
	inputValue: string = '';
	checked: boolean = false;
	selectedOption: string = '';

	constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string,texto: string, buttonConfirm: string, btnConfirmColor: string,  buttonCancel: string, showIcon: boolean, showOptionCheck: boolean, placeholder: string, options: Array<string>},
				public dialogRef: MatDialogRef<modalInputComponent>){}

	confirm(): void {
		const result = this.selectedOption === 'Otro:' ? this.inputValue : this.selectedOption;
		this.dialogRef.close(result);
	}

	cancel(): void {
		this.dialogRef.close(null);
	}
}