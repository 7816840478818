import { Injectable } from '@angular/core';
import { EDevice } from '@enums/common/sizes.enum';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStateService {

  private applicationDeviceDataSource: Subject<EDevice> =  new Subject();
  public applicationDeviceObservable: Observable<EDevice> = this.applicationDeviceDataSource.asObservable();

  public setApplicationDevice(newValue: EDevice): void {

    this.applicationDeviceDataSource.next(newValue);

  }

}
