import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { InvitadoJugadaGratis } from "@models/invitado_jugadaGratis.model";
import { ApiRouterService } from "@shared/services/api-router.service";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";

export interface Contact {
	name: string;
	number: string;
	initials: string;
}

@Injectable({
    providedIn:'root'
})
export class playFreeService extends ApiRouterService
{
    private Invitado: InvitadoJugadaGratis;

    private invitadoJugadaGratisObs: BehaviorSubject<InvitadoJugadaGratis>;

    public invitadoJugaGratis: Observable<InvitadoJugadaGratis>;

	private contacto: Contact;
	public contactoObservable: Observable<Contact>;
	private contactoSubject: BehaviorSubject<Contact>;


    constructor(private http: HttpClient,
                private AuthService: AuthenticationService){
        super();
        this.invitadoJugadaGratisObs =new BehaviorSubject<InvitadoJugadaGratis>(this.Invitado);
        this.invitadoJugaGratis=this.invitadoJugadaGratisObs.asObservable();

		this.contactoSubject = new BehaviorSubject<Contact>(null);
		this.contactoObservable = this.contactoSubject.asObservable();

    }

	public setContacto(contact: Contact): void {

		this.contacto = contact;
		this.contactoSubject.next(this.contacto);

		let invitado = new InvitadoJugadaGratis();
		invitado.nombre = contact.name;
		invitado.movil = parseInt(contact.number);
		invitado.mensaje = "";

		this.setInvitadoJugadaGratis(invitado);


	}

    setInvitadoJugadaGratis(invitado: InvitadoJugadaGratis)
    {
        this.Invitado=invitado;
        this.invitadoJugadaGratisObs.next(this.Invitado);
    }

    getConfiguracionJugadaGratis(): Observable<Object>
    {
        const token = this.AuthService.loadToken();

        let client = this.AuthService.decodeToken(token);

        let cabecera = new HttpHeaders({
            'Content-Type': 'application/json',
            "token": token
        });

        return this.http.get(`${this.URL}jugadaGratis/ObtenerConfiguracion`,{headers: cabecera})
    }

    addIntivado(pedido_id): Observable<Object>
    {
        const token = this.AuthService.loadToken();

        let client = this.AuthService.decodeToken(token);

        let cabecera = new HttpHeaders({
            'Content-Type': 'application/json',
            "token": token
        });

        return this.http.post(`${this.URL}jugadaGratisInvitados/${client.idClient}/${pedido_id}`,JSON.stringify(this.Invitado),{headers:cabecera});
    }
}