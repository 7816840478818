
<div class="games-primary-header-wrapper card">

  <div class="header-row mb-1">

    <span class="row-text">Elige uno o varios días</span>
 
    <div class="days-selection">

      <cdk-virtual-scroll-viewport orientation="horizontal" class="scroll">

        <div class="days">
          <app-day-lottery class="day" *ngFor="let game of games" [date]="game" (selectedEvent)="dateSelected($event)" (scrollEvent)="scroll($event)"></app-day-lottery>
        </div>

      </cdk-virtual-scroll-viewport>
      
    </div>

  </div>

</div>