<div class="bottomSheetContainer" (click)="dimiss()">
    <div class="button">
        <div class="btn-ln">
            <div class="line">

            </div>
            <h4>Regalo</h4>
        </div>
    </div>
    <div class="container">
        <div class="gift-data-wrapper">
            <div class="original-client-wrapper">
                {{jugada_regalada.original.nombre | titlecase}}
            </div>
            <span class="final-orginal-email">{{jugada_regalada.fecha_regalo | date:'yyyy-MM-dd'}}</span>
            <div class="separator"></div>
            <div class="final-client-wrapper">
                {{jugada_regalada.receptor ? jugada_regalada.receptor.nombre : 'Pendiente' | titlecase}} 
            </div>
            <span class="final-client-email">{{jugada_regalada.fecha_aceptacion ? (jugada_regalada.fecha_aceptacion | date:'yyyy-MM-dd')  : jugada_regalada.email_receptor }}</span>
        </div>
        <div class="cancel-wrapper" *ngIf="!jugada_regalada.receptor">
            <button class="cancel-button" (click)="cancelGiftedPlay()">Cancelar</button>
        </div>
    </div>
</div>