import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@api/services/authentication.service';
import { ToastrService } from 'ngx-toastr';

interface RecoverPasswordMessage {
	type: 'error' | 'warning' | 'success';
	message: string;
}

@Component({
	selector: 'app-recover-password',
	templateUrl: './recover-password.component.html',
	styleUrls: [
		'./recover-password.component.scss'
	]
})
export class RecoverPasswordComponent {

	recoverPasswordMessage: RecoverPasswordMessage | null;

	recoverPasswordForm: FormGroup;

	constructor(private authService: AuthenticationService, private toastrService: ToastrService) {

		this.recoverPasswordForm = new FormGroup({ email: new FormControl('', Validators.email) });
		this.recoverPasswordMessage = null;

	}

	/**
	 * Method that receives the submitted form from the view.
	 */
	public recoverPassword(): void {

		if (this.recoverPasswordForm.valid) {

			this.authService.forguetPasswordComponent(this.recoverPasswordForm.controls.email.value).subscribe(x => {
				this.toastrService.success("Se te ha enviado un correo para recuperar tu contraseña.");
				this.recoverPasswordMessage = { type: 'success', message: 'Se te ha enviado un correo para recuperar tu contraseña.' };
			}, err => {
				this.toastrService.error('Hubo algun error al enviar el correo, es posible que el correo introducido no este registrado.');
				this.recoverPasswordMessage = { type: 'error', message: 'Hubo algun error al enviar el correo, es posible que el correo introducido no este registrado.' };
			});

		} else {
			this.toastrService.error('El valor introducido no es válido.');
			this.recoverPasswordMessage = { type: 'error', message: 'El valor introducido no es válido.' };

		}

	}

}
