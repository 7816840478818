<div class="modal">
    <div class="icon" *ngIf="data.showIcon">
        <img src="/assets/images/icons/shield-check.svg" alt="img"/>
    </div>
    <h4 mat-dialog-title>{{data.title}}</h4>
    <div class="curpo" mat-dialog-content>{{data.texto}}</div>
    <!-- Cierra directamente el modal. -->
    <div class="footer">
        <button class="btn btn-outline-primary" [ngClass]="{'mb0': data.showOptionCheck}"  [mat-dialog-close]="false">{{data.buttonCancel}}</button>
        <button class="btn ml2" [ngClass]="{ 'btn-cool' : groupMode(), 'btn-danger': dangerBtn(), 'btn-primary' : !dangerBtn(), 'mb0':data.showOptionCheck }" [mat-dialog-close]="onClose(true)">{{data.buttonConfirm}}</button>
        <div class="noMostrar" *ngIf="data.showOptionCheck">
            <label class="custom-radio-checkbox">
                <input class="custom-radio-checkbox__input" type="checkbox" name="checkbox" (change)="onCheckboxChange($event)"/>
                <span class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
                <span class="custom-radio-checkbox__text">No volver a mostrar</span>
            </label>
        </div>
    </div>
</div>