import { Component, Inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UserApiService } from "@api/services/user.service";
import { ValidatorsService } from "@api/services/validators.service";
import { ToastrService } from "ngx-toastr";

@Component({
    selector:'app-modal-pin',
    templateUrl:'./modalPin.component.html',
    styleUrls:['./modalPin.component.scss']
})
export class modalPinComponent
{
	public oldPin: string;
	public newPin: string;
	public newRepeatPin: string;

	public pinForm: FormGroup;

	public disabled: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string, mode: string, parameters: any},
		public userService: UserApiService,
		private dialogRef: MatDialogRef<modalPinComponent>,
		private fb: FormBuilder,
		private toastrService: ToastrService) {

		this.pinForm = this.fb.group({
			oldPin: new FormControl('', [Validators.pattern('^[0-9]{4,}$')]),
			newPin: new FormControl('', [Validators.pattern('^[0-9]{4}$')]),
			newRepeatPin: new FormControl('', [Validators.pattern('^[0-9]{4}$')]),
		});

    }

	get oldPinInvalid(): boolean | undefined {
		return this.pinForm.get('oldPin')?.invalid && this.pinForm.get('oldPin')?.touched;
	}

	get newPinInvalid(): boolean | undefined {
		return this.pinForm.get('newPin')?.invalid && this.pinForm.get('newPin')?.touched;
	}

	get newRepeatPinInvalid(): boolean | undefined {
		return this.pinForm.get('newRepeatPin')?.invalid && this.pinForm.get('newRepeatPin')?.touched;
	}

	stop() {
		if (this.data.mode == "Confirm") {
			this.close();
			return;
		}
		this.dismiss();
	}

	dismiss() {
		this.dialogRef.close(false);
	}

	close() {
		this.dialogRef.close();
	}

	submit() {
		if (this.data.mode == "Confirm") {
			this.confirm();
			return;
		}
		this.check();
	}

	check() {
		if (this.pinForm.valid) {
			this.oldPin = this.pinForm.controls.oldPin.value;
			this.newPin = this.pinForm.controls.newPin.value;
			this.newRepeatPin = this.pinForm.controls.newRepeatPin.value;
		}
		else {
			this.toastrService.error("El pin debe ser de 4 dígitos y contener solo números");
			return;
		}
		this.userService.checkSecurityPin(this.oldPin).subscribe((res: any) => {
			if (res.status == 200) {
				this.dialogRef.close(true);
			}
			else {
				this.toastrService.error(res.u_message);
			}
		});
	}

	confirm() {
		if (this.pinForm.valid) {
			this.oldPin = this.pinForm.controls.oldPin.value;
			this.newPin = this.pinForm.controls.newPin.value;
			this.newRepeatPin = this.pinForm.controls.newRepeatPin.value;
		}
		else {
			this.toastrService.error("El pin debe ser de 4 dígitos y contener solo números");
			return;
		}
		if (!this.data.parameters.new) {
			this.userService.checkSecurityPin(this.oldPin).subscribe((res: any) => {
				if (res.status == 200) {
					if (this.data.parameters.delete) {
						this.userService.setSeguridad(false, false, false).subscribe((x: any) => {
							if (x.status == 200) {
								this.toastrService.success("Configuración guardada correctamente");
								this.dialogRef.close(true);
								return;
							}
							else {
								this.toastrService.error(x.u_message);
							}
						});
					}
					else if (this.data.parameters.changePin) {
						this.userService.setSecurityPin(this.newPin).subscribe((x: any) => {
							if (x.status == 200) {
								this.toastrService.success("Pin cambiado correctamente");
								this.dialogRef.close();
								return;
							}
							else {
								this.toastrService.error(x.u_message);
							}
						});
					}
					else {
						this.userService.setSeguridad(this.data.parameters.acceso_app, this.data.parameters.compra_producto, this.data.parameters.solicitud_transferencia).subscribe((x: any) => {
							if (x.status == 200) {
								this.toastrService.success("Configuración guardada correctamente");
								this.dialogRef.close(false);
								return;
							}
							else {
								this.toastrService.error(x.u_message);
							}
						});
					}
				}
				else {
					this.toastrService.error(res.u_message);
				}
			});
		}
		else {
			this.userService.setSeguridad(this.data.parameters.acceso_app, this.data.parameters.compra_producto, this.data.parameters.solicitud_transferencia, this.newPin).subscribe((x: any) => {
				if (x.status == 200) {
					this.toastrService.success("Configuración guardada correctamente");
					this.dialogRef.close(false);
					return;
				}
				else {
					this.toastrService.error(x.u_message);
				}
			});
		}
	}
}