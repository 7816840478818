<div class="wrapper">
	<swiper-container slides-per-view="1" :space-between="0" navigation="true" centered-slides="true" css-mode="true">
		<swiper-slide *ngFor="let slide of slides;" [ngStyle]="{ 'background-image': 'url(' + slide.src + ')'}">
			<div class="block">
				<div class="title">{{slide.title}}</div>
				<div class="subtitle">{{slide.subtitle}}</div>
			</div>
			<div class="skip" (click)="dismiss()">
				<span>Saltar</span>
			</div>
		</swiper-slide>
	</swiper-container>
</div>