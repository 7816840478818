import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MantenimientoGuard } from '@shared/guards/mantenimiento.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./layouts/main/main.module').then(m => m.MainModule)
  },
  {
    path: 'acceso',
    loadChildren: () => import('./layouts/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'mantenimiento',
    loadChildren: () => import('./modules/mantenimiento/mantenimiento.module').then(m => m.MantenimientoModule),
    canActivate:[MantenimientoGuard]
  }

];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
