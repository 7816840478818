<div class="bottomSheetContainer" (click)="dimiss()">
    <div class="button">
        <div class="btn-ln">
            <div class="line">

            </div>
            <h4>Detalles de tus Premios</h4>
        </div>
    </div>
    <div class="container">
        <div class="show-prizes-list">
            <div *ngFor="let prize of this.prizeDetails;">
                <div class="category">
                    <div class="color-category"></div>
                    <div class="category-text">{{prize.premio.aciertos}}</div>
                    <div class="category-prize">{{prize.importe}}€</div>
                </div>
            </div>
        </div>
    </div>
</div>